import {InputGroup} from "./InputGroup";

export const TextInput = (props) => {
    const {
        id,
        value,
        defaultValue,
        disabled = false,
        className = "",
        onChange = (v) => null,
        onBlur = (v) => null
    } = props;
    return <input
        id={id}
        className={`Input Input--type-text ${className}`}
        type={'text'}
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        onBlur={(e) => onBlur(e.target.value)}
        onChange={(e) => onChange(e.target.value)}
    />;
};

export const TextInputGroup = (props) => {
    return <InputGroup {...props}>
        <TextInput {...props}/>
    </InputGroup>
};