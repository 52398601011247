import {useEffect, useRef, useState} from "react";
import {LIGHT_COLOR} from "../../constants/colors";
import convert from 'color-convert';
import {TextInput} from "./TextInput";
import {NumberInput} from "./NumberInput";

export const ColorPicker = (props) => {
    const {offset, id, value = LIGHT_COLOR, onChange = (v) => null} = props;
    const {opacity, hex, rgb, hsv} = value;
    const hue = hsv[0];
    const colorPickerHsl = `hsl(${hue} 100% 50%)`;

    const svRef = useRef(null);
    const svSliderRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        const el = svRef.current;
        const slider = svSliderRef.current;
        if (!el || !slider) return;
        const updateColor = (e) => {
            const boundingRect = el?.getBoundingClientRect();
            const pos = {
                x: (e.clientX - boundingRect.x).clamp(0, boundingRect.width),
                y: (e.clientY - boundingRect.y).clamp(0, boundingRect.height)
            };
            slider.style.left = `${pos.x}px`;
            slider.style.top = `${pos.y}px`;
            const newHsv = [
                hue,
                Math.round(pos.x.map(0, boundingRect.width, 0, 100)),
                Math.round(pos.y.map(0, boundingRect.height, 100, 0))];
            onChange({
                opacity,
                hsv: newHsv,
                rgb: convert.hsv.rgb(...newHsv),
                hex: '#' + convert.hsv.hex(...newHsv)
            });
        };
        const drag = (e) => {
            if (isDragging) updateColor(e);
        }
        const startDragging = (e) => {
            updateColor(e);
            setIsDragging(true);
        };
        const stopDragging = (e) => {
            setIsDragging(false);
        };
        el?.addEventListener('mousedown', startDragging);
        window.addEventListener('mousemove', drag);
        window.addEventListener('mouseup', stopDragging)
        return () => {
            el?.removeEventListener('mousedown', startDragging);
            window.removeEventListener('mousemove', drag);
            window.removeEventListener('mouseup', stopDragging);
        }
    }, [onChange, opacity, hue, isDragging]);

    // useEffect(() => {
    //     const el = svRef.current;
    //     const slider = svSliderRef.current;
    //     if (!el || !slider) return;
    //     const boundingRect = el?.getBoundingClientRect();
    //     slider.style.left = `${hsv[1].map(0, 100, 0, boundingRect.width)}px`;
    //     slider.style.top = `${hsv[2].map(0, 100, boundingRect.height, 0)}px`;
    // }, [hsv])

    return (
        <div className={'ColorPicker-Container'} style={{top: offset}}>
            <div className={'ColorPicker-Row'}>
                <div
                    ref={svRef}
                    className={'ColorPicker-2DSlider'}
                    style={{backgroundColor: colorPickerHsl}}>
                    <div
                        className={'ColorPicker-Thumb'}
                        ref={svSliderRef}/>
                </div>
                <div className={'ColorPicker-Slider ColorPicker-Slider--hue'}>
                    <input
                        id={`${id}-hueSlider`}
                        className={'ColorPicker-Hue'}
                        type={'range'}
                        min={0}
                        max={360}
                        orient={'vertical'}
                        value={hsv[0]}
                        onChange={e => {
                            const newHsv = [Number(e.target.value), hsv[1], hsv[2]];
                            onChange({
                                opacity,
                                hsv: newHsv,
                                hex: '#' + convert.hsv.hex(...newHsv),
                                rgb: convert.hsv.rgb(...newHsv)
                            })
                        }}/>
                </div>

                <div className={'ColorPicker-Slider ColorPicker-Slider--opacity'}
                     style={{backgroundColor: hex}}>
                    <input
                        id={`${id}-opacitySlider`}
                        className={'ColorPicker-Opacity'}
                        type={'range'}
                        min={0} max={100}
                        orient={'vertical'}
                        value={opacity}
                        onChange={e => onChange({opacity: Number(e.target.value), hsv, hex, rgb})}/>
                </div>

            </div>
            <div className={'ColorPicker-Row'}>
                <TextInput
                    id={`${id}-hex`}
                    className={'ColorPicker-Hex'}
                    value={hex}
                    onChange={v => {
                        const newHex = v;
                        onChange({
                            opacity,
                            hex: newHex,
                            rgb: convert.hex.rgb(newHex),
                            hsv: convert.hex.hsv(newHex)
                        });
                    }}/>
                <NumberInput
                    id={`${id}-opacity`}
                    className={'ColorPicker-TextOpacity'}
                    value={opacity}
                    onChange={v => onChange({opacity: v.clamp(0, 100), hsv, hex, rgb})}/>
            </div>
        </div>
    );
};