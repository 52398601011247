import {useEffect, useState} from "react";
import {matchAnyVariables, matchAtLeastSelectedVariables} from "./visualizationFilters";

/**
 * Filters an array of visualizations to those that can visualize an array of variables. If some of the variables have the
 * attribute selected=true, the visualize has to visualize all selected variables, otherwise the visualize must
 * only visualize some of the variables.
 * @param viz {Object[]} array of visualizations.
 * @param variables {Object[]} array of variables with the attribute "selected".
 * @return {Object[]} array of filtered visualizations.
 */
const useVariableFilter = (viz, variables) => {
    const [filteredViz, setFilteredViz] = useState(viz);

    useEffect(() => {
        // without variables, show all visualizations
        if (!variables || variables.length === 0) {
            setFilteredViz(viz);
            return;
        }
        const selected_variables = variables.filter((variable) => variable.selected);
        const hasSelectedVariables = selected_variables?.length === 0;
        setFilteredViz(viz.map((visualization) => {
            // get array of attributes
            const combinations = (hasSelectedVariables
                    ? matchAnyVariables(visualization, variables)
                    : matchAtLeastSelectedVariables(visualization, variables));
            // if (visualize.id === "vertical_strip_plot") console.log(match);
            // console.log(visualize.id, combinations);
            return {...visualization, combinations: combinations, rank: visualization.rank + combinations?.[0]?.rank};
        }).filter((visualization) => !!visualization.combinations));
    }, [variables, viz]);

    return filteredViz;
};

export default useVariableFilter;