import React from "react";
import {useDrop} from "react-dnd";

const DroppableAttribute = ({label, attribute, children}) => {
    const [{canDrop, isOver, itemType}, dropRef] = useDrop(() => ({
        accept: 'VARIABLE',
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            itemType: monitor.getItemType(),
        }),
        canDrop: (variable, monitor) => {
            return attribute.matchesVariable(variable);
        },
        hover: (variable, monitor) => {

        },
        drop: (variable, monitor) => {
            return {name: label, attribute: attribute};
        }
    }));

    const hoverClass = (isOver && canDrop) ? ' Drop--hover' : '';
    const dragClass = itemType ? ` Drop--dragging-${canDrop ? 'valid' : 'invalid'}` : '';

    return (
        <div
            ref={dropRef}
            className={'Drop' + dragClass + hoverClass}>
            <div className={'Drop-Label'}>
                <span>{attribute.name}</span>
                <span>{attribute.isRequired && '*'}</span>
            </div>
            <div className={'Drop-Container'}>
                {children}
            </div>
        </div>
    );
};

export default DroppableAttribute;