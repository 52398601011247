import {useEffect, useState} from "react";

/**
 * Filters an array of visualizations to those that can visualize an array of relationships.
 * @param viz {Object[]} array of visualizations.
 * @param relationships {Object[]} array of relationships with the attribute "selected".
 * @return {Object[]} array of filtered visualizations.
 */
const useSettingsFilter = (viz, relationships) => {
    const [filteredViz, setFilteredViz] = useState(viz);

    useEffect(() => {
        const selected_relationships = relationships.filter((relationship) => relationship.selected);
        setFilteredViz(viz.filter((visualization) => (
            vizMatchesRelationships(visualization, selected_relationships)
        )));
    }, [relationships, viz]);

    return filteredViz;
};

const vizMatchesRelationships = (visualization, relationships) => {
    for (const relationship of relationships) {
        if (!visualization.relationships.some((rel) => rel === relationship.value)) {
            return false;
        }
    }
    return true;
};

export default useSettingsFilter;