import React from "react";
import {useVisualization} from "../../context/VisualizationContext";
import Worksheet from "../visualizations/Worksheet";
import {Button} from "../Button";
import {downloadFile} from "../../helpers/downloadFile";

const CustomizationPanel = () => {
    const {visualization} = useVisualization();

    function exportImage() {
        const canvas = document.getElementById('worksheet-visualize');
        const img = canvas.toDataURL('image/png');
        downloadFile(img, 'chart');
    }

    return <>
        <div className={'Pane Pane--inspector'}>
            {visualization && <visualization.Inspector/>}
            <Button
                className={'Button--span'}
                onClick={exportImage}>
                Export
            </Button>
        </div>
        <div className={'Pane --padding-tb Pane--grow --layer-0'}>
            <div className={'Workspace'}>
                <Worksheet/>
            </div>
        </div>
    </>;
};

export default CustomizationPanel;