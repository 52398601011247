import {SHAPE_CIRCLE, SHAPE_CROSS, SHAPE_PLUS, SHAPE_SQUARE, SHAPE_TRIANGLE} from "../constants/configuration";

export const drawCircle = (ctx, x, y, r) => {
    ctx.beginPath();
    ctx.arc(x, y, r, 0, 2 * Math.PI);
    ctx.closePath();
};

export const drawPlus = (ctx, x, y, r) => {
    ctx.beginPath();
    ctx.moveTo(x - r, y);
    ctx.lineTo(x + r, y);
    ctx.moveTo(x, y - r);
    ctx.lineTo(x, y + r);
}

export const drawCross = (ctx, x, y, r) => {
    const offset = r * .7071067811865476; // Math.cos(45 * (Math.PI / 180))
    ctx.beginPath();
    ctx.moveTo(x - offset, y - offset);
    ctx.lineTo(x + offset, y + offset);
    ctx.moveTo(x + offset, y - offset);
    ctx.lineTo(x - offset, y + offset);
};

// todo: make circle & square equal optically
export const drawSquare = (ctx, x, y, r) => {
    ctx.beginPath();
    ctx.rect(x - r, y - r, r * 2, r * 2);
};

export const drawTriangle = (ctx, x, y, r) => {
    const xOffset = r * .8660254037844387; // Math.cos(30 * (Math.PI / 180))
    const yOffset = r * .49999999999999994; // Math.sin(30 * (Math.PI / 180))
    ctx.beginPath();
    ctx.moveTo(x, y - r);
    ctx.lineTo(x + xOffset, y + yOffset);
    ctx.lineTo(x - xOffset, y + yOffset);
    ctx.closePath();
};

export const drawShape = (shape, ctx, x, y, r) => {
    switch (shape) {
        case SHAPE_CIRCLE:
            drawCircle(ctx, x, y, r);
            break;
        case SHAPE_PLUS:
            drawPlus(ctx, x, y, r);
            break;
        case SHAPE_CROSS:
            drawCross(ctx, x, y, r);
            break;
        case SHAPE_SQUARE:
            drawSquare(ctx, x, y, r);
            break;
        case SHAPE_TRIANGLE:
            drawTriangle(ctx, x, y, r);
            break;
        default:
            break;
    }

};