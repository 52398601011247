import {useEffect, useState} from "react";
import vizGroups from '../assets/visualization_groups.json';

const useVizGroups = (viz) => {
    const [filteredVizGroups, setFilteredVizGroups] = useState(vizGroups);
    useEffect(() => {
        // console.log('group viz');
        setFilteredVizGroups(vizGroups.map((group) => {
            const filteredViz = viz.filter((viz) => viz.group === group.id);
            if (filteredViz.length === 0) return null;
            if (filteredViz.length === 1) return filteredViz[0];
            return {...group, rank: filteredViz[0].rank, combinations: filteredViz[0].combinations};
        }).filter((group) => !!group).sort((a, b) => b.rank - a.rank));
    }, [viz]);
    return filteredVizGroups;
};

export default useVizGroups;