/**
 * Determines, whether an attribute can properly encode a variable.
 * @param attribute {Object}
 * @param variable {Object}
 * @return {boolean|number}
 */
const attributeMatchesVariable = (attribute, variable) => {
    // todo: multiple, hasEqualGapsPer,
    // exclusion criteria
    if (!attribute.scale.some((scale) => scale === variable.scale)) return false;
    if (attribute.dataType
        && !attribute.dataType.some((type) => type === variable.type)) return false;
    if (typeof attribute.isUnique === "boolean"
        && attribute.isUnique !== variable.isUnique) return false;
    if (typeof attribute.hasNoSignChange === "boolean"
        && attribute.hasNoSignChange !== variable.hasNoSignChange) return false;
    if (typeof attribute.isCyclic === "boolean"
        && attribute.isCyclic !== variable.isCyclic) return false;
    if (typeof attribute.max === "number"
        && attribute.max < variable.maxValue) return false;

    // ranking criteria
    let rank = 0;
    if (typeof attribute.hasEqualGaps === "boolean") {
        if (variable.equalGapRatio < .5) return false;
        rank -= (1 - variable.equalGapRatio) * 2;
    }
    if (typeof attribute.maxUniqueValueCount?.[1] === "number") {
        if (attribute.maxUniqueValueCount[1] < variable.uniqueValueCount) return false;
        if (attribute.maxUniqueValueCount[0] < variable.uniqueValueCount) rank -= 1;
    }
    if (typeof attribute.hasNarrowSpread === "boolean") {
        if (attribute.hasNarrowSpread && variable.rangeRatio < .9) rank -= 1;
        else if (!attribute.hasNarrowSpread && variable.rangeRatio > .9) rank -= 1;
    }
    return rank;
};

const attributesCrossMatchVariables = (matches) => {
    for (const match of matches) {
        if (typeof match.attribute.symmetricalSubCategory === "string") {
            if (!match.variable.symmetricalSubCategories.some(str => (
                str === matches.find((_match) => _match.attribute.name === match.attribute.symmetricalSubCategory).variable.name
            ))) return false;
        }
        if (typeof match.attribute.uniqueSubCategory === "string") {
            if (!match.variable.uniqueSubCategories.some(str => (
                str === matches.find((_match) => _match.attribute.name === match.attribute.uniqueSubCategory).variable.name
            ))) return false;
        }
        if (typeof match.attribute.hasEqualGapsPer === "string") {
            // if (!match.variable.hasEqualGapsPer.some())
            // todo
        }
    }
    return true;
};

/**
 * Finds all possible combinations for a 2-dimensional array.
 * @param input {array[]}
 * @param result
 * @return {array[]}
 */
const getCombinations = (input, result = []) => {
    if (!input.length) return result;
    const [first, ...rest] = input;
    const combinations = [];
    if (!result.length) {
        combinations.push(...first.map(item => [item]));
    } else {
        for (const item of first) {
            for (const existing of result) {
                combinations.push([...existing, item]);
            }
        }
    }
    return getCombinations(rest, combinations);
};

/**
 * Finds all possible of attributes and variables (matches), where each variable matches only one attribute.
 * @param attributes {Object[]} Objects with the property 'matches[]'
 * @return {array[]} all valid combinations.
 */
const getUniqueVariableCombinations = (attributes) => {
    const attributeCount = attributes.length;
    const combinations = getCombinations(attributes.map((attribute) => attribute.matches));
    const uniqueCombinations = [];
    for (const combination of combinations) {
        const usedVariables = new Set();

        let valid = true;
        for (let i = 0; i < attributeCount; i++) {
            const attribute = attributes[i];
            const variable = combination[i];

            // todo: find.usedCount === find.max
            if (usedVariables.has(variable.name)) {
                valid = false;
                break;
            }
            // todo: find.usedCount++
            usedVariables.add(variable.name);
        }
        if (valid) {
            uniqueCombinations.push(attributes.map((attribute, i) => (
                {...attribute, uniqueMatch: combination[i]}
            )));
        }
    }
    return uniqueCombinations;
};

/**
 * Creates an array of all valid combinations of attributes and variables for a specific visualize.
 * @param viz {Object} the object from visualization_types.json.
 * @param variables {Object[]} an array of categorized variables from the uploaded parseCSVString set.
 * @return {boolean|{variable: *, attribute: *}[][]} a two-dimensional array of attribute-variable matches. Each entry
 * in the outer array represents a possible combination of attributes and variables.
 */
export const matchAnyVariables = (viz, variables) => {
    // filter attributes that are required
    // then filter variables that match this attribute
    const matchedAttributes = viz.attributes.filter((attribute) => attribute.isRequired).map((attribute) => ({
        attribute: attribute,
        matches: variables.map(variable => ({...variable, rank: attributeMatchesVariable(attribute, variable)}))
            .filter(variable => variable.rank !== false)
    }));
    if (matchedAttributes.some((attribute) => attribute.matches.length === 0)) return false;
    // find combinations if all attributes match at least one variable
    const uniqueCombinations = getUniqueVariableCombinations(matchedAttributes).map((combination) => ({
        matches: combination.map((match) => ({attribute: match.attribute, variable: match.uniqueMatch})),
        rank: combination.reduce((prev, curr) => prev + (curr.attribute.rank ?? curr.uniqueMatch.rank ?? 0), 0),
    }));
    const combinations = uniqueCombinations.filter((combination) => attributesCrossMatchVariables(combination.matches));
    if (combinations.length === 0) return false;
    // rank combinations
    return combinations.sort((a, b) => b.rank - a.rank);
};

export const matchAtLeastSelectedVariables = (viz, variables) => {
    const selectedVariables = variables.filter(variable => variable.selected);
    // removed .filter((attribute) => attribute.isRequired)
    const matchedAttributes = viz.attributes.map((attribute) => ({
        attribute: attribute,
        matches: variables.map(variable => ({...variable, rank: attributeMatchesVariable(attribute, variable)}))
            .filter(variable => variable.rank !== false)
    })).filter(attribute => (attribute.attribute.isRequired || attribute.matches.length > 0));

    if (matchedAttributes.some((attribute) => (attribute.matches.length === 0))) return false;
    const uniqueCombinations = getUniqueVariableCombinations(matchedAttributes).map((combination) => ({
        matches: combination.map((match) => ({attribute: match.attribute, variable: match.uniqueMatch})),
        rank: combination.reduce((prev, curr) => prev + (curr.attribute.rank ?? curr.uniqueMatch.rank ?? 0), 0),
    }));
    const matchingCombinations = uniqueCombinations.filter((combination) => attributesCrossMatchVariables(combination.matches));
    if (matchingCombinations.length === 0) return false;
    const combinations = matchingCombinations.filter((combination) => {
        for (const variable of selectedVariables) {
            if (!combination.matches.some(match => match.variable.name === variable.name)) return false;
        }
        return true;
    })
    if (combinations.length === 0) return false;
    // rank combinations
    return combinations.sort((a, b) => b.rank - a.rank);
};

export const matchOnlySelectedVariables = (viz, variables) => {
    // filter attributes that match this variable
    const matchedVariables = variables.filter((variable) => variable.selected).map((variable) => ({
        variable: variable,
        matches: viz.attributes.map(attribute => ({...attribute, rank: attributeMatchesVariable(attribute, variable)}))
            .filter(variable => variable.rank !== false)
    }));
    if (matchedVariables.some((variable) => variable.matches.length === 0)) return false;
    const uniqueCombinations = getUniqueVariableCombinations(matchedVariables).map((combination) => (
        combination.map((match) => ({attribute: match.uniqueMatch, variable: match.variable}))
    )).filter((combination) => {
        // remove combinations, that are missing a required attribute of the visualize
        const usedAttributes = new Set(combination.map((match) => match.attribute));
        const requiredAttributes = viz.attributes.filter((attribute) => attribute.isRequired);
        for (const attribute of requiredAttributes) {
            if (!usedAttributes.has(attribute)) return false;
        }
        return true;
    });
    // filter combinations, where the attributes cross-match
    const combinations = uniqueCombinations.filter((combination) => attributesCrossMatchVariables(combination));
    // rank combinations
    if (combinations.length > 0) return combinations;
};