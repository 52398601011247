import {useEffect, useLayoutEffect, useRef, useState} from "react";
import {wrapGrid} from "animate-css-grid";
import tools from "../assets/tools.json";
import {getDescription} from "../helpers/getDescription";
import parse from 'html-react-parser';
import {useRecommendations} from "../context/RecommendationContext";
import {useVisualization} from "../context/VisualizationContext";
import useAnimatedGrid from "../helpers/useAnimatedGrid";

const Recommendations = (props) => {
    const recommendations = useRecommendations();
    const visualization = useVisualization();
    const gridRef = useRef();
    const {itemCount, imgWidth} = useAnimatedGrid(gridRef);

    return <div className={'Recommendations-Group'} ref={gridRef}>
        {recommendations.groups.map((viz) => (
            <Recommendation
                key={viz.group ?? viz.id}
                viz={viz}
                selected={recommendations.selected === viz.id}
                imgWidth={imgWidth}
                itemCount={itemCount}
                createVisualization={() => visualization.create(viz)}
                onClick={() => recommendations.select(viz.id)}
            />
        ))}
    </div>;
};

const Recommendation = (props) => {
    const {viz, selected, imgWidth, itemCount, onClick, createVisualization} = props;
    return <div
        className={'Viz' + (selected ? ' Viz--selected' : '')}
        style={(selected) ? {gridColumn: `auto / span ${Math.min(3, itemCount)}`} : {}}
        onClick={onClick}>
        {/* a single wrapper is required for animations to work */}
        <div className={'Viz-AnimationWrapper'}>
            <div
                className={'Viz-Wrapper' + (selected ? ' Viz-Wrapper--selected' : '')}>
                <div className={'Viz-Preview'}>
                    <div
                        className={'Viz-ImgContainer'}
                        style={{width: `${imgWidth}px`, height: `${imgWidth}px`}}>
                        <img
                            className={'Viz-Img'}
                            src={process.env.PUBLIC_URL + `/assets/images/viz/${viz?.src ?? 'placeholder.png'}`}
                            alt={viz?.names?.[0] ?? viz.id ?? 'Unnamed Visualization'}/>
                    </div>
                    <h5 className={'Viz-Name'}>{viz?.names?.[0] ?? viz?.id}</h5>
                </div>
                <div className={'Viz-Details' + (selected ? '' : ' --display-none')}>
                    <p className={'Viz-Description'}>
                        {parse(getDescription(viz, false))}
                    </p>
                    {viz?.combinations?.length > 0
                        ? <button
                            className={'Button'}
                            onClick={createVisualization}>
                            Create Chart
                        </button>
                        : null}
                </div>
            </div>
        </div>
    </div>;
};

// const Tool = (props) => {
//     const {id, viz} = props;
//     const tool = tools.find((tool) => tool.id === id);
//     if (!tool) return null;
//     return <a
//         className={'Viz-Tool'}
//         href={tool.href}
//         target={"_blank"}
//         rel={"noreferrer"}>
//         {tool.name}
//     </a>;
// };

export default Recommendations;