export const clamp = (x, min, max) => Math.min(Math.max(x, min), max);

/**
 * Returns a number whose value is limited to the given range.
 * @param {Number} min lower boundary of the output range
 * @param {Number} max upper boundary of the output range
 * @returns {Number} A number in the range [min, max]
 * @type Number
 */
// eslint-disable-next-line no-extend-native
Number.prototype.clamp = function(min, max) {
    return Math.min(Math.max(this, min), max);
};

/**
 * Maps a number from an input range [fromMin, fromMax] to an output range [toMin, toMax]
 * @param {Number} fromMin lower boundary of the input range
 * @param {Number} fromMax upper boundary of the input range
 * @param {Number} toMin lower boundary of the output range
 * @param {Number} toMax upper boundary of the output range
 * @returns {Number} A number mapped from the range [fromMin, fromMax] to the range [toMin, toMax]
 * @type Number
 */
// eslint-disable-next-line no-extend-native
Number.prototype.map = function(fromMin, fromMax, toMin, toMax) {
    // console.log('map', this, 'from', fromMin, ' - ', fromMax, 'to', toMin, ' - ', toMax);
    return (this - fromMin) * (toMax - toMin) / (fromMax - fromMin) + toMin;
}