import {InputGroup} from "./InputGroup";

export const NumberInput = (props) => {
    const {
        id, value, defaultValue, disabled = false, className = "",
        onChange = (v) => null, onBlur = (v) => null
    } = props;

    return <input
        id={id}
        className={`Input Input--type-number ${className}`}
        type={'number'}
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        onBlur={(e) => onBlur(Number(e.target.value))}
        onChange={(e) => onChange(Number(e.target.value))}
    />;
};

export const NumberInputGroup = (props) => {
    return <InputGroup {...props}>
        <NumberInput {...props}/>
    </InputGroup>
}