
const variableRegex = /{([A-Za-z-_]*)}/g;

export const getDescription = (viz, specific) => {
    const description = viz?.description ?? "";
    let variableDescription = viz?.descriptionAllVariables;
    if (!variableDescription) return description;

    // generate description
    const attributeNames = [...variableDescription.matchAll(variableRegex)].map((arr) => arr[1]);
    for (const attributeName of attributeNames) {
        const variable = viz?.combinations?.[0]?.matches?.find((match) => match.attribute?.name === attributeName)?.variable;
        if (!variable) return description;
        const className = `Viz-Variable ${variable.selected ? 'Viz-Variable--selected' : ''}`;
        const htmlString = `<span class="${className}">${variable.name}</span>`;
        variableDescription = variableDescription.replace(`{${attributeName}}`, htmlString);
    }
    return variableDescription;
};