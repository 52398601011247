export function calculateDiagonalDistance(vector) {
    const distance = Math.sqrt(vector.y ** 2 + vector.x ** 2);

    // Determine the direction based on the relative positions
    const direction = Math.atan2(vector.x, vector.y);

    // Convert direction to degrees and normalize to [-180, 180] range
    const degrees = (direction * 180) / Math.PI;
    const normalizedDegrees = (degrees + 360) % 360;
    const signedDegrees = normalizedDegrees > 180 ? normalizedDegrees - 360 : normalizedDegrees;

    return distance * Math.sign(signedDegrees);
}

export function calculateDistance(pos1, pos2) {
    const deltaX = pos2.x - pos1.x;
    const deltaY = pos2.y - pos1.y;
    return Math.sqrt(deltaX ** 2 + deltaY ** 2);
}