import {NumberInput} from "./NumberInput";
import {InputGroup} from "./InputGroup";

export const RangeInputGroup = (props) => {
    const {id, min, max} = props;
    return <InputGroup {...props}>
        <NumberInput
            {...props}
            id={`${id}-minValue`}
            value={min.value}
            defaultValue={min.defaultValue}
            disabled={min.disabled ?? false}
            onChange={min.onChange}
            onBlur={min.onBlur}/>
        <span className={'Input-Span'}>-</span>
        <NumberInput
            {...props}
            id={`${id}-maxValue`}
            value={max.value}
            defaultValue={max.defaultValue}
            disabled={max.disabled ?? false}
            onChange={max.onChange}
            onBlur={max.onBlur}/>
    </InputGroup>
};