import {InputGroup} from "./InputGroup";

// todo: arrow
export const Select = (props) => {
    const {id, options = [], onChange = (v) => null, defaultValue, value} = props;
    return <select
        className={'Select'}
        id={id}
        value={value}
        defaultValue={defaultValue}
        onChange={(e) => onChange(e.target.value)}>
        {options.map((opt, optIdx) => (
            <option
                className={'Select-Option'}
                key={optIdx}
                value={opt.value}>
                {opt.name}
            </option>
        ))}
    </select>;
};
export const SelectGroup = (props) => {
    return <InputGroup {...props}>
        <Select {...props}/>
    </InputGroup>
}