import {useEffect, useState} from "react";

/**
 * Automatically changes the class of the root element to match the browser's color scheme.
 * @return {{colorScheme: null|"dark"|"light"}} the selected color scheme.
 */
const useColorScheme = () => {
    const [colorScheme, setColorScheme] = useState(null);
    useEffect(() => {
        const updateColorScheme = (e) => {
            const addScheme = e?.matches ? 'dark' : 'light';
            const removeScheme = e?.matches ? 'light' : 'dark';
            setColorScheme(addScheme);
            // caution, removes all other visualizations!
            document.body.classList.remove(`--colorScheme-${removeScheme}`);
            document.body.classList.add(`--colorScheme-${addScheme}`);
        }
        updateColorScheme(window.matchMedia('(prefers-color-scheme: dark)')); // get initial setting
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', updateColorScheme); // event listener
        return () => window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', updateColorScheme);
    }, []);
    return {colorScheme};
};

export default useColorScheme;