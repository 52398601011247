import {Link} from "react-router-dom";
import Recommendations from "../Recommendations";
import {useDataHandler} from "../../context/DataContext";
import {useRecommendations} from "../../context/RecommendationContext";
import {useVisualization} from "../../context/VisualizationContext";
import {InspectorGroup} from "../input/Inspector";
import {Button} from "../Button";
import {CheckboxGroup} from "../input/Checkbox";

const RecommendationsPanel = () => {
    const handler = useDataHandler();
    const recommendations = useRecommendations();
    const vizHandler = useVisualization();

    return <>
        <div className={'Pane Pane--inspector'}>
            <div className={'Inspector --layer-1'}>
                <div className={'Inspector-Body'}>
                    <InspectorGroup title={'Variables'}>
                        {handler?.variables?.length > 0
                            ? handler?.variables?.map((variable, variableIdx) => (
                                // data uploaded: select variables
                                <CheckboxGroup
                                    key={variableIdx}
                                    id={`var-${variableIdx}-${variable.name}`}
                                    label={variable.name}
                                    value={variable.selected}
                                    highlight={recommendations.groups
                                        .find(g => g.name === recommendations.selected)
                                        ?.combinations?.[0]?.matches?.some(match => match.variable.name === variable.name)}
                                    disabled={false}
                                    onChange={v => handler.toggleVariable(variable, v)}
                                />
                            ))
                            : (
                                // no data uploaded: message
                                <Link className={'Caption'} to={'/data'}>
                                    Upload a file to obtain recommendations specific to your data.
                                </Link>
                            )}
                    </InspectorGroup>

                    <InspectorGroup title={'Relationships'}>
                        {recommendations.relationships?.map((r, i) => (
                            <CheckboxGroup
                                key={i}
                                id={`relationship-${r.name}`}
                                label={r.name}
                                value={r.selected}
                                disabled={false}
                                onChange={v => recommendations.toggleRelationship(r, v)}
                            />
                        ))}
                    </InspectorGroup>
                </div>
            </div>
            <Button
                className={'Button--span'}
                onClick={() => vizHandler.create(
                    recommendations.groups.find(g => g.id === recommendations.selected)
                )}>
                Create Visualization
            </Button>
        </div>
        <div className={'Pane Pane--grow --layer-0'}>
            <div className={'Workspace'}>
                <div className={'ScrollArea ScrollArea--padding --scrollbar-hidden'}>
                    <div className={'--padding-tb'}>
                        <header className={'Recommendations-Header'}>
                            <h1 className={'Recommendations-Headline'}>Recommended Visualizations</h1>
                            <p className={'Recommendations-Caption'}>
                                {recommendations.groups.length > 0
                                    ? 'Click on a visualize to see more details.'
                                    : 'No suitable visualizations. Try reducing the filters.'}
                            </p>
                        </header>
                        <Recommendations/>
                    </div>
                </div>
            </div>
        </div>
    </>;
};

export default RecommendationsPanel;