import YAxis from "./YAxis";

class CategoricalYAxis extends YAxis {
    constructor(viz, id, name, options) {
        super(viz, id, name, options);
        this.style.showGrid = false;
        this.style.tickLength = 0;
    }

    autoUpdateStyle(variable) {
        this.style.title = variable?.name ?? 'axis';
        this.updateTitleSize();
        this.updateLabelSize();
    }

    forEachTick(ctx, bounds, callback, options = {}) {
        const x = this.getAxisPosition(bounds);
        this.visualization.forEachGroup(({y, h, group}) => {
            callback(x, y + h * .5, group);
        }, bounds);
    }

    updateLabelSize(ctx = this.visualization.ctx) {
        if (!ctx) return;
        this.applyLabelStyles(ctx);
        let maxWidth = 0;
        this.visualization.attributes.label?.variables[0]?.uniqueValues.forEach(v => {
            const w = ctx.measureText(v).width;
            maxWidth = Math.max(maxWidth, w);
        })
        this.style.labelSize = maxWidth;
    }
}

export default CategoricalYAxis;