export const VIZ_BAR_CHART = 'bar_chart'; // (stacked/grouped) bar chart / pareto chart
export const VIZ_BOXPLOT = 'boxplot'; // boxplot / violin plot / range bar chart

export const VIZ_PLOT = 'plot'; // (connected) scatterplot / bubble chart /  (stacked) [area/line/spline] chart
export const VIZ_CIRCULAR_BAR_CHART = 'circular_bar_chart';
export const VIZ_RADIAL_BAR_CHART = 'radial_bar_chart';
export const VIZ_MARIMEKKO_CHART = 'marimekko_chart';
export const VIZ_RADAR_GRAPH = 'radar_graph';
export const VIZ_SLOPE_GRAPH = 'slope_chart';
export const VIZ_BUMP_CHART = 'bump_chart';
export const VIZ_HISTOGRAM = 'histogram';
export const VIZ_STEM_AND_LEAF = 'stem_and_leaf';
export const VIZ_DOT_PLOT = 'dot_plot';
export const VIZ_UNIT_CHART = 'unit_chart';
export const VIZ_WAFFLE_CHART = 'waffle_chart';
export const VIZ_PIE_CHART = 'pie_chart';
export const VIZ_TREEMAP = 'treemap';
export const VIZ_PACKED_BUBBLE_CHART = 'packed_bubble_chart';
export const VIZ_HEATMAP = 'heatmap';
export const VIZ_CIRCULAR_HEATMAP = 'circular_heatmap';