import XAxis from "./XAxis";

class CategoricalXAxis extends XAxis {
    constructor(viz, id, name, options) {
        super(viz, id, name, options);
        this.style.showGrid = false;
        this.style.tickLength = 0;
    }

    autoUpdateStyle(variable) {
        this.style.title = variable?.name ?? 'axis';
        this.updateTitleSize();
        this.updateLabelSize();
    }

    forEachTick(ctx, bounds, callback, options = {}) {
        const y = this.getAxisPosition(bounds);
        this.visualization.forEachGroup(({x, w, group}) => {
            callback(x + w * .5, y, group);
        }, bounds);
    }

    updateLabelSize(ctx = this.visualization.ctx) {
        if (!ctx) return;
        this.applyLabelStyles(ctx);
        let maxHeight = 0;
        this.visualization.attributes.label?.variables[0]?.uniqueValues.forEach(v => {
            const m = ctx.measureText(v);
            const h = m.actualBoundingBoxDescent + m.actualBoundingBoxAscent;
            maxHeight = Math.max(maxHeight, h);
        })
        this.style.labelSize = maxHeight;
    }
}

export default CategoricalXAxis;