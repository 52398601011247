import React, {useEffect, useRef} from "react";
import {useVisualization} from "../../context/VisualizationContext";

const Worksheet = () => {
    const {visualization} = useVisualization();
    const ref = useRef(null);
    const chartRef = useRef(null);
    const sgfRef = useRef(null);

    useEffect(() => {
        const ctx = chartRef.current?.getContext('2d');
        const sgfCtx = sgfRef.current?.getContext('2d');
        visualization.updateCtx(ctx, sgfCtx);
        visualization.draw();
    }, [visualization]);

    useEffect(() => {
        if (!visualization || !ref.current) return;
        const getPosition = (e) => {
            const bounds = ref.current?.getBoundingClientRect();
            return {x: e.clientX - bounds.x, y: e.clientY - bounds.y};
        };
        const onMouseMove = (e) => visualization.onMouseMove(getPosition(e));
        const onMouseDown = (e) => visualization.onMouseDown(getPosition(e));
        const onMouseUp = (e) => visualization.onMouseUp(getPosition(e));

        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mousedown', onMouseDown);
        window.addEventListener('mouseup', onMouseUp);
        return () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mousedown', onMouseDown);
            window.removeEventListener('mouseup', onMouseUp);
        };
    }, [visualization]);

    return <div
        ref={ref}
        id={'worksheet'}
        className={'Worksheet'}
        style={{
            width: `${visualization?.dimensions.w}px`,
            height: `${visualization?.dimensions.h}px`,
            cursor: 'unset'
        }}>
        <canvas
            ref={chartRef}
            id={'worksheet-visualize'}
            className={'Worksheet-Canvas'}
            width={visualization?.dimensions.w}
            height={visualization?.dimensions.h}/>
        <canvas
            ref={sgfRef}
            id={'worksheet-overlay'}
            className={'Worksheet-Canvas'}
            width={visualization?.dimensions.w}
            height={visualization?.dimensions.h}/>
        {/*<div className={'Worksheet-Handle Worksheet-Handle--c-tr'}/>*/}
        {/*<div className={'Worksheet-Handle Worksheet-Handle--c-tl'}/>*/}
        {/*<div className={'Worksheet-Handle Worksheet-Handle--c-br'}/>*/}
        {/*<div className={'Worksheet-Handle Worksheet-Handle--c-bl'}/>*/}
    </div>;
};

export default Worksheet;