export const DELIMITERS = [
    {name: 'Auto-Detect', value: ''}, // auto-detect
    {name: ',', value: ','},
    {name: ';', value: ';'},
    {name: 'Tab', value: '\t'},
    {name: '|', value: '|'},
];

export const LINEBREAKS = [
    {name: 'Auto-Detect', value: ''}, // auto-detect
    {name: '\\n', value: '\n'},
    {name: '\\r', value: '\r'},
    {name: '\\n\\r', value: '\n\r'},
    {name: '\\r\\n', value: '\r\n'},
];

export const DATA_TYPE = {
    INTEGER: 'integer',
    FLOAT: 'float',
    STRING: 'string',
    DATE: 'date'
};

export const DATA_SCALE = {
    QUANTITATIVE: 'quantitative',
    ORDINAL: 'ordinal',
    NOMINAL: 'categorical',
    DATE: 'date'
}

export const VISUAL_RELATIONSHIPS = [
    {value: 'time-series', name: 'Time-Series'},
    {value: 'ranking', name: 'Ranking'},
    {value: 'part-to-whole', name: 'Part-to-Whole'},
    {value: 'deviation', name: 'Deviation'},
    {value: 'distribution', name: 'Distribution', char: [
            {value: 'spread', name: 'Spread'},
            {value: 'shape', name: 'Shape'},
            {value: 'percentiles', name: '25th, 50th and 75th percentile'}
        ]},
    {value: 'correlation', name: 'Correlation'},
    // {value: 'geospatial', name: 'Geospatial'},
    {value: 'nominal_comparison', name: 'Nominal Comparison'},
];

export const ERROR_TYPES = {
    FIELDS: "FieldMismatch",
    QUOTES: "Quotes",
    DELIMITER: "Delimiter"
};
export const ERROR_CODES = {
    TOO_FEW_FIELDS: "TooFewFields",
    TOO_MANY_FIELDS: "TooManyFields",
    DELIMITER: "UndetectableDelimiter",
    QUOTES: "InvalidQuotes",
}
// MissingQuotes ?