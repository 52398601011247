import Axis from "./Axis";
import {FACTORS, POSITION_END, POSITION_START} from "../../../constants/configuration";
import {updateInputValue} from "../../../helpers/updateInputValue";

class XAxis extends Axis {
    constructor(viz, id, name, options = {}) {
        super(viz, id ?? 'x', name ?? 'x-axis', options);
        this.style.title = 'x-axis';
        this.style.position = POSITION_END;
        this.style.titleBaseline = 'top';
        this.style.labelBaseline = 'top';
    }

    getAxisPosition(bounds) {
        return this.style.position === POSITION_START ? bounds.t : bounds.b;
    }

    modifyDatapointBounds(bounds) {
        const h = this.getTotalSize();
        let modifiedBounds = {...bounds};
        modifiedBounds.h -= h;
        if (this.style.position === POSITION_START) modifiedBounds.t += h;
        else modifiedBounds.b -= h;
        return modifiedBounds;
    }

    getBounds(bounds) {
        const h = this.getTotalSize();
        let modifiedBounds = {...bounds};
        modifiedBounds.h = h;
        if (this.style.position === POSITION_START) modifiedBounds.b = modifiedBounds.t + h;
        else modifiedBounds.t = modifiedBounds.b - h;
        return modifiedBounds;
    }

    forEachTick(ctx, bounds, callback, options = {}) {
        const min = options?.min ?? this.style.minTick;
        const max = options?.max ?? this.style.maxTick;
        const interval = options?.interval ?? this.style.tickInterval;
        const y = this.getAxisPosition(bounds);
        for (let tick = min; tick <= max; tick += interval) {
            const x = tick.map(min, max, bounds.l, bounds.r);
            callback(x, y, tick / this.style.tickFactor);
        }
    }

    drawGrid(ctx, bounds) {
        if (!this.style.showGrid) return;
        this.applyGridStyles(ctx);
        ctx.beginPath();
        this.forEachTick(ctx, bounds, (x, y) => {
            ctx.moveTo(x, bounds.t);
            ctx.lineTo(x, bounds.b);
        }, {interval: this.style.gridInterval});
        ctx.stroke();
    }

    drawTicks(ctx, bounds) {
        ctx.beginPath();
        this.applyTickStyles(ctx);
        this.forEachTick(ctx, bounds, (x, y) => {
            ctx.moveTo(x, y);
            ctx.lineTo(x, y + (this.style.tickLength * this.getOffsetModifier()));
        });
        const y = this.getAxisPosition(bounds);
        ctx.moveTo(bounds.l, y);
        ctx.lineTo(bounds.r, y);
        ctx.stroke();
    }

    drawTitle(ctx2, bounds) {
        const ctx = this.visualization.ctx;
        ctx.save();
        this.applyTitleStyles(ctx);
        const y = this.getAxisPosition(bounds);
        const yOffset = this.getTitleOffset();
        ctx.translate(bounds.l + bounds.w * .5, y + yOffset);
        ctx.rotate(this.style.titleRotate * (Math.PI / 180));
        ctx.fillText(this.getTitle(), 0, 0);
        ctx.restore();
    }

    drawLabels(ctx, bounds) {
        this.applyLabelStyles(ctx);
        this.forEachTick(ctx, bounds, (x, y, value) => {
            const text = this.style.prefix + value + this.style.suffix;
            const yOffset = this.getLabelOffset();
            ctx.fillText(text, x, y + yOffset);
        });
    }

    updatePosition(v) {
        const st = v === POSITION_START;
        this.style.position = v;
        this.style.titleBaseline = st ? 'bottom' : 'top';
        this.style.labelBaseline = st ? 'bottom' : 'top';
    }

    checkHover(pos, bounds) {
        const baselineY = this.style.position === POSITION_START ? bounds.t : bounds.b;
        const y2 = baselineY + this.getTotalSize() * this.getOffsetModifier();
        const hovering = {title: false, titleMargin: false, label: false, labelMargin: false, ticks: false};
        const isHoveringX = pos.x > bounds.l && pos.x <= bounds.r;
        const isHoveringY = pos.y > Math.min(baselineY, y2) && pos.y <= Math.max(baselineY, y2);
        if (!isHoveringX || !isHoveringY) return hovering;
        if (this.checkHoverTitle(pos.y, baselineY))
            return {...hovering, title: true};
        if (this.checkHoverTitleMargin(pos.y, baselineY))
            return {...hovering, titleMargin: true};
        if (this.checkHoverLabel(pos.y, baselineY))
            return {...hovering, label: true};
        if (this.checkHoverLabelMargin(pos.y, baselineY))
            return {...hovering, labelMargin: true};
        if (this.checkHoverTicks(pos.y, baselineY))
            return {...hovering, ticks: true};
        return hovering;
    }

    highlightTitle(ctx, bounds) {
        const h = this.style.titleSize;
        const y = (this.style.position === POSITION_START ? bounds.t - h : bounds.b) + this.getTitleOffset();
        ctx.fillRect(bounds.l, y, bounds.w, h);
    }

    highlightTitleMargin(ctx, bounds) {
        const h = this.style.titleMargin;
        const y = (this.style.position === POSITION_START ? bounds.t - h : bounds.b) + this.getTitleMarginOffset();
        ctx.fillRect(bounds.l, y, bounds.w, h);
    }

    highlightLabel(ctx, bounds) {
        const h = this.style.labelSize;
        const y = (this.style.position === POSITION_START ? bounds.t - h : bounds.b) + this.getLabelOffset();
        ctx.fillRect(bounds.l, y, bounds.w, h);
    }

    highlightLabelMargin(ctx, bounds) {
        const h = this.style.labelMargin;
        const y = (this.style.position === POSITION_START ? bounds.t - h : bounds.b) + this.getLabelMarginOffset();
        ctx.fillRect(bounds.l, y, bounds.w, h);
    }

    highlightTicks(ctx, bounds) {
        const h = this.style.tickLength;
        const y = (this.style.position === POSITION_START ? bounds.t - h : bounds.b);
        ctx.fillRect(bounds.l, y, bounds.w, h);
    }

    handleResize(dif, datapointBounds) {
        this.highlight(this.resizing, datapointBounds);
        const {title, titleMargin, label, labelMargin, ticks} = this.resizing;
        const f = this.style.position === POSITION_START ? 1 : -1
        if (title) this.resizeTitle(dif.y * f);
        if (titleMargin) this.resizeTitleMargin(dif.y * f);
        if (label) this.resizeLabel(dif.y * f);
        if (labelMargin) this.resizeLabelMargin(dif.y * f);
        if (ticks) this.resizeTicks(dif.y * f);
    }
}

export default XAxis;