import chevronUp from '../../assets/icons/chevron-up.png';
import {useState} from "react";

export const InspectorGroup = (props) => {
    const {children, title} = props;
    const [collapsed, setCollapsed] = useState(false);
    const collapse = () => setCollapsed(prev => !prev);

    return <div className={'Inspector-Group'}>
        <header className={'Inspector-Header Inspector-Header--collapsible'} onClick={collapse}>
            <h4 className={'Inspector-Headline'}>
                {title}
            </h4>
            <button className={'Inspector-CollapseButton'}>
                <img
                    style={{transform: `rotate(${collapsed ? 180 : 0}deg)`}}
                    src={chevronUp}
                    alt={''}/>
            </button>
        </header>
        <div className={'Inspector-Settings'
            + (collapsed ? ' Inspector-Settings--collapsed' : '')}>
            <div className={'Inspector-SettingsContainer'}>
                {children}
            </div>
        </div>
    </div>
};