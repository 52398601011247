import React from "react";
import BoxPlot from "../core/BoxPlot";
import Attribute from "../attributes/Attribute";
import {
    ORIENTATION_OPTIONS,
    ORIENTATION_VERTICAL,
    SCALE_QUANTITATIVE,
    SHAPE_CIRCLE
} from "../../../constants/configuration";
import YAxis from "../axes/YAxis";
import {drawShape} from "../../../helpers/drawShape";
import CategoricalXAxis from "../axes/CategoricalXAxis";
import XAxis from "../axes/XAxis";
import CategoricalYAxis from "../axes/CategoricalYAxis";
import {InspectorGroup} from "../../input/Inspector";
import {parseColor} from "../../../helpers/color";
import {CheckboxGroup} from "../../input/Checkbox";
import {NumberInputGroup} from "../../input/NumberInput";
import {ColorInputGroup} from "../../input/ColorInput";
import {SelectGroup} from "../../input/Select";

class BoxAndWhiskerPlot extends BoxPlot {
    constructor() {
        super();
        this.attributes.position = new Attribute(this, 'position', {
            isRequired: true,
            scale: [SCALE_QUANTITATIVE]
        });
        this.axes.y = new YAxis(this, 'y', 'y-axis', {});
    }

    autoUpdateAxisStyle(records) {
        super.autoUpdateAxisStyle(records);
        const quantitativeAxis = this.style.orientation === ORIENTATION_VERTICAL
            ? this.axes.y : this.axes.x;
        quantitativeAxis.autoUpdateStyle(this.attributes.position.variables[0]);
    }

    drawDataPoints(ctx, bounds) {
        let violinMaxDensity = 0;
        this.forEachSegment(({records}) => {
            const posVariable = this.attributes.position.variables[0];
            const values = records.map(r => r[posVariable.name]);
            const axis = this.getQuantitativeAxis();
            const steps = this.getViolinSteps(axis.style.minTick, axis.style.maxTick, 40);
            const shape = this.getViolinShape(values, steps);
            violinMaxDensity = Math.max(violinMaxDensity, ...shape);
        }, bounds);
        console.log(violinMaxDensity);
        this.style.violinMaxDensity = violinMaxDensity;

        this.forEachSegment((segment) => {
            this.drawViolin(ctx, segment);
            this.drawBoxAndWhiskers(ctx, segment);
            this.drawDots(ctx, segment);
        }, bounds);
    }

    applyViolinStyles(ctx) {
        const s = this.style;
        ctx.fillStyle = parseColor(s.violinFillColor);
        ctx.strokeStyle = parseColor(s.violinStrokeColor);
        ctx.lineWidth = s.violinStrokeWidth;
    } 

    applyBoxAndWhiskerStyles(ctx) {
        const s = this.style;
        ctx.fillStyle = parseColor(s.boxFillColor);
        ctx.strokeStyle = parseColor(s.boxStrokeColor);
        ctx.lineWidth = s.boxStrokeWidth;
    }

    applyDotStyles(ctx) {
        const s = this.style;
        ctx.fillStyle = parseColor(s.dotFillColor);
        ctx.strokeStyle = parseColor(s.dotStrokeColor);
        ctx.lineWidth = s.dotStrokeWidth;
    }

    getViolinSteps(min, max, count) {
        const steps = [];
        const stepSize = (max - min) / count;
        for (let step = min; step <= max; step += stepSize) steps.push(step);
        return steps;
    }

    getViolinShape(data, steps) {
        return steps.map(step => {
            const density = this.getKernelDensity(step, data);
            return density;
        });
    }

    getKernelDensity(x, data) {
        // Silverman's rule of thumb for kernel density estimation
        const variance = this.calculateVariance(data);
        const bandwidth = 1.06 * Math.sqrt(variance) * Math.pow(data.length, -0.2);
        let sum = 0;
        for (let i = 0; i < data.length; i++) {
            sum += Math.exp(-0.5 * Math.pow((x - data[i]) / bandwidth, 2)) / (bandwidth * Math.sqrt(2 * Math.PI));
        }
        return sum / data.length;
    }

    calculateVariance(data) {
        const mean = data.reduce((acc, val) => acc + val, 0) / data.length;
        return data.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0) / data.length;
    }

    drawViolinEdge(ctx, step, weight, {x, y, w, h}) {
        const pos = this.mapAccordingToOrientation(step, {x, y, w, h});
        const extrusion = (weight / this.style.violinMaxDensity) * this.style.violinWidth;
        if (this.style.orientation === ORIENTATION_VERTICAL)
            ctx.lineTo(x + w * .5 + extrusion, pos);
        else
            ctx.lineTo(pos, y + h * .5 + extrusion);
    }

    drawViolin(ctx, segment) {
        const {x, y, w, h, records} = segment;
        if (!this.style.showViolin) return;
        this.applyViolinStyles(ctx);
        const posVariable = this.attributes.position.variables[0];
        const values = records.map(r => r[posVariable.name]);
        const axis = this.getQuantitativeAxis();
        const steps = this.getViolinSteps(axis.style.minTick, axis.style.maxTick, 40);
        const shape = this.getViolinShape(values, steps);

        ctx.beginPath();
        if (this.style.orientation === ORIENTATION_VERTICAL) ctx.moveTo(x + (w * .5), y + h);
        else ctx.moveTo(x, y + (h * .5));
        for (let i = 0; i < steps.length; i++) this.drawViolinEdge(ctx, steps[i], -shape[i], segment);
        if (this.style.orientation === ORIENTATION_VERTICAL) ctx.lineTo(x + (w * .5), y);
        else ctx.lineTo(x + w, y + (h * .5));
        for (let i = steps.length - 1; i >= 0; i--) this.drawViolinEdge(ctx, steps[i], shape[i], segment);
        ctx.closePath();
        ctx.fill();
        if (this.style.violinStrokeWidth > 0) ctx.stroke();
    }

    calculatePercentile(values, percentile) {
        const sortedValues = values.slice().sort((a, b) => a - b);
        const n = sortedValues.length;
        const position = percentile * (n + 1);
        const lowerIdx = Math.floor(position) - 1;
        const upperIdx = Math.ceil(position) - 1;

        if (position === Math.floor(position)) return sortedValues[lowerIdx];
        else return (sortedValues[lowerIdx] + sortedValues[upperIdx]) / 2;
    }

    calculateQuartiles(values, threshold = 1.5) {
        const q1 = this.calculatePercentile(values, .25);
        const q2 = this.calculatePercentile(values, .5);
        const q3 = this.calculatePercentile(values, .75);
        const lowerThreshold = q1 - threshold * (q3 - q1);
        const upperThreshold = q3 + threshold * (q3 - q1);
        return {q1, q2, q3, lowerThreshold, upperThreshold};
    }

    drawBoxAndWhiskers(ctx, segment) {
        const {records} = segment;
        if (!this.style.showBoxAndWhisker) return;
        this.applyBoxAndWhiskerStyles(ctx);
        const posVariable = this.attributes.position.variables[0];
        const values = records.map(r => r[posVariable.name]);
        const {q1, q2, q3, lowerThreshold, upperThreshold} = this.calculateQuartiles(values, this.style.outlierThreshold);
        const valuesWithoutOutliers = values.slice().filter(v => v >= lowerThreshold && v <= upperThreshold);
        const lowerExtreme = Math.min(...valuesWithoutOutliers);
        const upperExtreme = Math.max(...valuesWithoutOutliers);
        const posQ1 = this.mapAccordingToOrientation(q1, segment);
        const posQ2 = this.mapAccordingToOrientation(q2, segment);
        const posQ3 = this.mapAccordingToOrientation(q3, segment);
        const posLower = this.mapAccordingToOrientation(lowerExtreme, segment);
        const posUpper = this.mapAccordingToOrientation(upperExtreme, segment);
        this.drawBox(ctx, posQ1, posQ3, segment);
        this.drawMedian(ctx, posQ2, segment);
        this.drawWhisker(ctx, posQ1, posLower, segment);
        this.drawWhisker(ctx, posQ3, posUpper, segment);
    }

    mapAccordingToOrientation(value, {x, y, w, h}) {
        if (this.style.orientation === ORIENTATION_VERTICAL)
            return y + value?.map(this.axes.y.style.minTick, this.axes.y.style.maxTick, h, 0);
        else
            return x + value?.map(this.axes.x.style.minTick, this.axes.x.style.maxTick, 0, w);
    }

    drawBox(ctx, startPos, endPos, {x, y, w, h}) {
        ctx.beginPath();
        if (this.style.orientation === ORIENTATION_VERTICAL)
            ctx.rect(x, startPos, w, (endPos - startPos));
        else
            ctx.rect(startPos, y, (endPos - startPos), h);
        ctx.fill();
        if (this.style.boxStrokeWidth > 0) ctx.stroke();
    }

    drawMedian(ctx, pos, {x, y, w, h}) {
        ctx.beginPath();
        if (this.style.orientation === ORIENTATION_VERTICAL) {
            ctx.moveTo(x, pos);
            ctx.lineTo(x + w, pos);
        } else {
            ctx.moveTo(pos, y);
            ctx.lineTo(pos, y + h);
        }
        if (this.style.boxStrokeWidth > 0) ctx.stroke();
    }

    drawWhisker(ctx, startPos, endPos, {x, y, w, h}) {
        ctx.beginPath();
        const length = this.style.whiskerLength;
        if (this.style.orientation === ORIENTATION_VERTICAL) {
            const cx = x + w * .5;
            ctx.moveTo(cx, startPos);
            ctx.lineTo(cx, endPos);
            ctx.moveTo(cx - length * .5, endPos);
            ctx.lineTo(cx + length * .5, endPos);
        } else {
            const cy = y + h * .5;
            ctx.moveTo(startPos, cy);
            ctx.lineTo(endPos, cy);
            ctx.moveTo(endPos, cy - length * .5);
            ctx.lineTo(endPos, cy + length * .5);
        }
        if (this.style.boxStrokeWidth > 0) ctx.stroke();
    }

    drawDots(ctx, {x, y, w, h, group, segment, records}) {
        if (!this.style.showStrip) return;
        this.applyDotStyles(ctx);
        const posVariable = this.attributes.position.variables[0];
        records.forEach((record) => {
            const value = record[posVariable.name];
            const r = this.style.maxRadius;
            const pos = this.mapAccordingToOrientation(value, {x, y, w, h});
            if (this.style.orientation === ORIENTATION_VERTICAL)
                drawShape(SHAPE_CIRCLE, ctx, x + w * .5, pos, r);
            else
                drawShape(SHAPE_CIRCLE, ctx, pos, y + h * .5, r);
            ctx.fill();
            if (this.style.strokeWidth > 0) ctx.stroke();
        });
    }

    updateOrientation(orientation) {
        if (orientation === this.style.orientation) return;
        if (orientation === ORIENTATION_VERTICAL) {
            this.axes.x = new CategoricalXAxis(this);
            this.axes.y = new YAxis(this, 'y', 'y-axis');
        } else {
            this.axes.x = new XAxis(this, 'x', 'x-axis');
            this.axes.y = new CategoricalYAxis(this);
        }
        this.style.orientation = orientation;
        this.autoUpdateStyle();
        this.rerenderInspector();
    }

    DataPointsInspectorGroup = () => {
        return (
            <InspectorGroup
                id={`${this.id}`}
                title={'Data Points'}>
                <SelectGroup
                    id={`${this.id}-orientation`}
                    label={'Orientation'}
                    options={ORIENTATION_OPTIONS}
                    defaultValue={this.style.orientation}
                    onChange={v => {
                        // this.style.orientation = v;
                        this.updateOrientation(v);
                        this.onChange();
                    }}/>
                <ColorInputGroup
                    id={`${this.id}-fillColors`}
                    label={'Color'}
                    defaultValue={this.style.fillColors[0]}
                    onBlur={v => {
                        this.style.fillColors[0] = v;
                        this.onChange();
                    }}/>

                <h5 className={'Inspector-SubHeadline'}>Box-And-Whisker</h5>
                <CheckboxGroup
                    id={`${this.id}-showBoxAndWhisker`}
                    label={'Show'}
                    defaultValue={this.style.showBoxAndWhisker}
                    onChange={v => {
                        this.style.showBoxAndWhisker = v;
                        this.onChange();
                    }}/>
                <NumberInputGroup
                    id={`${this.id}-whiskerLength`}
                    label={'Whisker Length'}
                    defaultValue={this.style.whiskerLength}
                    onBlur={v => {
                        this.style.whiskerLength = v;
                        this.onChange();
                    }}/>
                <NumberInputGroup
                    id={`${this.id}-boxStrokeWidth`}
                    label={'Stroke Width'}
                    defaultValue={this.style.boxStrokeWidth}
                    onBlur={v => {
                        this.style.boxStrokeWidth = v;
                        this.onChange();
                    }}/>
                <NumberInputGroup
                    id={`${this.id}-outlierThreshold`}
                    label={'Outlier Threshold'}
                    defaultValue={this.style.outlierThreshold}
                    onBlur={v => {
                        this.style.outlierThreshold = v;
                        this.onChange();
                    }}/>

                <h5 className={'Inspector-SubHeadline'}>Violin</h5>
                <CheckboxGroup
                    id={`${this.id}-showViolin`}
                    label={'Show'}
                    defaultValue={this.style.showViolin}
                    onChange={v => {
                        this.style.showViolin = v;
                        this.onChange();
                    }}/>
                <NumberInputGroup
                    id={`${this.id}-violinWidth`}
                    label={'Width'}
                    defaultValue={this.style.violinWidth}
                    onBlur={v => {
                        this.style.violinWidth = v;
                        this.onChange();
                    }}/>
                <NumberInputGroup
                    id={`${this.id}-violinStrokeWidth`}
                    label={'Stroke Width'}
                    defaultValue={this.style.violinStrokeWidth}
                    onBlur={v => {
                        this.style.violinStrokeWidth = v;
                        this.onChange();
                    }}/>

                <h5 className={'Inspector-SubHeadline'}>Dots</h5>
                <CheckboxGroup
                    id={`${this.id}-showStrip`}
                    label={'Show'}
                    defaultValue={this.style.showStrip}
                    onChange={v => {
                        this.style.showStrip = v;
                        this.onChange();
                    }}/>
                <NumberInputGroup
                    id={`${this.id}-dotStrokeWidth`}
                    label={'Stroke Width'}
                    defaultValue={this.style.dotStrokeWidth}
                    onBlur={v => {
                        this.style.dotStrokeWidth = v;
                        this.onChange();
                    }}/>

            </InspectorGroup>
        );
    }
}

export default BoxAndWhiskerPlot;