import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    createHashRouter,
    RouterProvider,
    Navigate,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Editor from "./components/Editor";
import ErrorPage from "./components/ErrorPage";
import DataPanel from "./components/panels/DataPanel";
import VizPanel from "./components/panels/RecommendationsPanel";
import CustomizationPanel from "./components/panels/CustomizationPanel";
import AttributesPanel from "./components/panels/AttributesPanel";
import {DataContextProvider} from "./context/DataContext";
import {VisualizationContextProvider} from "./context/VisualizationContext";
import {RecommendationContextProvider} from "./context/RecommendationContext";

const router = createHashRouter([
    {
        path: "/",
        element: (
            <DataContextProvider>
                <RecommendationContextProvider>
                    <VisualizationContextProvider>
                        <Editor/>
                    </VisualizationContextProvider>
                </RecommendationContextProvider>
            </DataContextProvider>
        ),
        errorElement: <ErrorPage/>,
        children: [{
            // index: true,
            path: "data",
            element: <DataPanel/>
        }, {
            path: "visualize",
            element: <VizPanel/>
        }, {
            path: "attributes",
            element: <AttributesPanel/>
        }, {
            path: "customize",
            element: <CustomizationPanel/>
        }, {
            path: "*",
            element: <Navigate to={'/data'} replace/>
        }]
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
