import Visualization from "../core/Visualization";
import {SHAPE_OPTIONS, TREEMAP_OPTIONS, TREEMAP_SLICED, TREEMAP_SQUARIFIED} from "../../../constants/configuration";
import {InspectorGroup} from "../../input/Inspector";
import {NumberInputGroup} from "../../input/NumberInput";
import {ColorInputGroup} from "../../input/ColorInput";
import {SelectGroup} from "../../input/Select";

class Treemap extends Visualization {
    attributes = {
        area: {variables: []},
        label: {variables: []},
    };

    constructor() {
        super();
    }

    getSlicedRectanglePositions(bounds) {
        let rectangles = [];
        const isVertical = bounds.h > bounds.w;
        const pos = {x: 0, y: 0};
        const areaVariable = this.attributes.area.variables[0]?.name;
        const total = this.records.reduce((a, b) => a + b[areaVariable], 0);
        this.records.sort((a, b) => b[areaVariable] - a[areaVariable]).forEach((record) => {
            const label = record[this.attributes.label.variables[0]?.name];
            const area = record[areaVariable];
            const rectSize = area.map(0, total, 0, (isVertical ? bounds.h : bounds.w));
            const rectangle = {label, x: pos.x, y: pos.y, w: bounds.w, h: bounds.h};
            rectangle[isVertical ? 'h' : 'w'] = rectSize;
            rectangles.push(rectangle);
            pos[isVertical ? 'y' : 'x'] += rectSize;
        });
        return rectangles;
    }

    getSquarifiedRectanglePositions(bounds) {
        let rectangles = [];
        const areaVariable = this.attributes.area.variables[0]?.name;
        const total = this.records.reduce((a, b) => a + b[areaVariable], 0);
        const remaining = {
            value: total,
            w: bounds.w,
            h: bounds.h
        };
        let isVertical = bounds.h < bounds.w;
        let column = [];
        let columnPos = {x: 0, y: 0};
        let columnSize = 0;
        let aspectRatio;
        let columnValue = 0;

        this.records.sort((a, b) => b[areaVariable] - a[areaVariable]).forEach((record) => {
            const label = record[this.attributes.label.variables[0]?.name];
            const value = record[areaVariable];
            const newColumnValue = columnValue + value;
            const newColumnSize = newColumnValue.map(0, remaining.value, 0, (isVertical ? remaining.w : remaining.h));
            const rectSize = value.map(0, newColumnValue, 0, (isVertical ? remaining.h : remaining.w));
            const newAspectRatio = Math.max(newColumnSize, rectSize) / Math.min(newColumnSize, rectSize);
            // newAspectRatio is an aspect ratio >= 1. we want the aspect ratio to be as close to 1 as possible.
            if (!aspectRatio || newAspectRatio < aspectRatio) {
                columnValue = newColumnValue;
                aspectRatio = newAspectRatio;
                columnSize = newColumnSize;
                column.push({value, label});
            } else {
                let offset = 0;
                column.forEach((child) => {
                    const size = child.value.map(0, columnValue, 0, (isVertical ? remaining.h : remaining.w));
                    let rectangle = isVertical
                        ? {label: child.label, x: columnPos.x, y: columnPos.y + offset, w: columnSize, h: size}
                        : {label: child.label, x: columnPos.x + offset, y: columnPos.y, w: size, h: columnSize}
                    rectangles.push(rectangle);
                    offset += size;
                });
                columnPos[isVertical ? 'x' : 'y'] += columnSize;
                remaining[isVertical ? 'w' : 'h'] -= columnSize;
                remaining.value -= columnValue;
                isVertical = remaining.w > remaining.h;
                column = [{value, label}];
                columnValue = value;
                columnSize = value.map(0, remaining.value, 0, (isVertical ? remaining.w : remaining.h));
                const w = value.map(0, remaining.value, 0, (isVertical ? remaining.w : remaining.h));
                const h = (isVertical ? remaining.h : remaining.w);
                aspectRatio = Math.max(w, h) / Math.min(w, h);
            }
        });
        let offset = 0;
        column.forEach((child) => {
            const size = child.value.map(0, columnValue, 0, (isVertical ? remaining.h : remaining.w));
            let rectangle = isVertical
                ? {label: child.label, x: columnPos.x, y: columnPos.y + offset, w: columnSize, h: size}
                : {label: child.label, x: columnPos.x + offset, y: columnPos.y, w: size, h: columnSize}
            rectangles.push(rectangle);
            offset += size;
        });
        return rectangles;
    }

    getRectanglePositions(bounds) {
        switch (this.style.layout) {
            case TREEMAP_SQUARIFIED:
                return this.getSquarifiedRectanglePositions(bounds);
            case TREEMAP_SLICED:
                return this.getSlicedRectanglePositions(bounds);
            default:
                console.warn(`treemap layout "${this.style.layout}" is undefined`);
                return [];
        }
    }

    drawDataPoints(ctx, bounds) {
        const rectangles = this.getRectanglePositions(bounds);
        ctx.save();
        ctx.translate(bounds.l, bounds.t);
        this.applyDataPointStyles(ctx);
        rectangles.forEach((r) => {
            ctx.beginPath();
            ctx.rect(r.x, r.y, r.w, r.h);
            ctx.fill();
            if (this.style.strokeWidth > 0) ctx.stroke();
        });
        this.applyLabelStyles(ctx);
        rectangles.forEach((r) => {
            const m = ctx.measureText(r.label);
            const textHeight = m.actualBoundingBoxDescent + m.actualBoundingBoxAscent;
            if (m.width > r.w || textHeight > r.h) return;
            const x = r.x + r.w * .5;
            const y = r.y + r.h * .5;
            if (this.style.labelStrokeWidth > 0) ctx.strokeText(r.label, x, y);
            ctx.fillText(r.label, x, y);
        });
        ctx.restore();
    }


    DataPointsInspectorGroup = () => {
        return (
            <InspectorGroup
                id={`${this.id}`}
                title={'Data Points'}>
                <ColorInputGroup
                    id={`${this.id}-fillColors`}
                    label={'Fill'}
                    defaultValue={this.style.fillColors[0]}
                    onChange={v => {
                        this.style.fillColors[0] = v;
                        this.onChange();
                    }}/>
                <ColorInputGroup
                    id={`${this.id}-strokeColors`}
                    label={'Stroke'}
                    defaultValue={this.style.strokeColors[0]}
                    onChange={v => {
                        this.style.strokeColors[0] = v;
                        this.onChange();
                    }}/>
                <NumberInputGroup
                    id={`${this.id}-strokeWidth`}
                    label={'Stroke Width'}
                    defaultValue={this.style.strokeWidth}
                    onBlur={v => {
                        this.style.strokeWidth = v;
                        this.onChange();
                    }}/>
                <SelectGroup
                    id={`${this.id}-layout`}
                    label={'Layout'}
                    defaultValue={this.style.layout}
                    options={TREEMAP_OPTIONS}
                    onChange={v => {
                        this.style.layout = v;
                        this.onChange();
                    }}/>
            </InspectorGroup>
        );
    }
}

export default Treemap;