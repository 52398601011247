export const InputGroup = (props) => {
    const {className = ''} = props;
    return <div className={`InputGroup ${className}`}>
        <div className={'InputGroup-LabelContainer'}>
            <label
                className={'Label'}
                htmlFor={props.id}
            >{props.label}</label>
        </div>
        <div className={`InputGroup-InputContainer`}>
            {props.children}
        </div>
    </div>;
};


