import React, {useState} from "react";
import {ColorPicker} from "./ColorPicker";
import {InputGroup} from "./InputGroup";

export const ColorInput = (props) => {
    const {
        id,
        value,
        defaultValue,
        disabled = false,
        className = "",
        onChange = (v) => null,
        onBlur = (v) => null
    } = props;
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [color, setColor] = useState(defaultValue ?? value);
    const [offset, setOffset] = useState(0);

    const wrapOnChange = (v) => {
        setColor(v);
        onChange(v);
    }

    const openColorPicker = (e) => {
        const pos = e.target.getBoundingClientRect().top + 22;
        setOffset(pos);
        setShowColorPicker(true)
    };

    const onClickOutside = () => {
        onBlur(color);
        setShowColorPicker(false);
    }

    return <div className={'Input Input--type-color'}>
        <button
            id={id}
            className={'Input-Color'}
            style={{
                backgroundColor: value?.hex ?? color?.hex,
                opacity: value?.opacity ?? color?.opacity + "%"
            }}
            onClick={openColorPicker}>
        </button>
        <div
            className={'ColorPicker'}
            style={{display: showColorPicker ? 'unset' : 'none'}}>
            <div
                className={'ColorPicker-Overlay'}
                onClick={onClickOutside}/>
            {/*<div className={'ColorPicker-Container'}/>*/}
            <ColorPicker
                {...props}
                offset={offset}
                value={value ?? color}
                onChange={wrapOnChange}
            />
        </div>
    </div>;
};
export const ColorInputGroup = (props) => {
    return <InputGroup {...props}>
        <ColorInput {...props}/>
    </InputGroup>;
};
export const ColorArrayGroup = (props) => {
    const {id, values, onChange = (v) => null} = props;
    return <InputGroup {...props}>
        {values.map((value, valueIdx) => (
            <ColorInput
                {...props}
                key={valueIdx}
                id={id + '-' + valueIdx}
                value={value}
                onChange={v => {
                    const newValues = [...values];
                    newValues[valueIdx] = v;
                    onChange(newValues)
                }}/>
        ))}
    </InputGroup>;
}