import React, {createContext, useContext, useState} from "react";
import ScatterPlot from "../components/visualizations/types/ScatterPlot";
import {useNavigate} from 'react-router-dom';
import {useRecommendations} from "./RecommendationContext";
import {useDataHandler} from "./DataContext";
import Histogram from "../components/visualizations/types/Histogram";
import {
    VIZ_BAR_CHART,
    VIZ_BOXPLOT,
    VIZ_HISTOGRAM,
    VIZ_PIE_CHART,
    VIZ_PLOT,
    VIZ_TREEMAP, VIZ_UNIT_CHART
} from "../constants/visualizations";
import PieChart from "../components/visualizations/types/PieChart";
import Treemap from "../components/visualizations/types/Treemap";
import LineChart from "../components/visualizations/types/LineChart";
import BarChart from "../components/visualizations/types/BarChart";
import BoxAndWhiskerPlot from "../components/visualizations/types/BoxAndWhiskerPlot";
import UnitChart from "../components/visualizations/types/UnitChart";

const VisualizationContext = createContext(null);

export const VisualizationContextProvider = ({children}) => {
    const navigate = useNavigate();
    const dataHandler = useDataHandler();
    const recommendations = useRecommendations();
    const [visualization, setVisualization] = useState(new ScatterPlot([], [], {}));

    function create(viz) {
        const visualization = recommendations.groups.find(g => g.id === viz.id);
        const VisualizationClass = getVisualizationClass(viz.type);
        if (!VisualizationClass) return;
        const map = {};
        visualization?.combinations[0]?.matches?.forEach(pair => {
            map[pair.attribute.name] = pair.variable.name;
        });
        const _visualization = new VisualizationClass();
        _visualization.translateMapToAttributes(map, dataHandler.variables);
        _visualization.updateData(dataHandler.records);
        setVisualization(_visualization);
        navigate('/attributes');
    }

    function reset() {
        setVisualization(new ScatterPlot([], [], {}));
    }

    return <VisualizationContext.Provider value={{visualization, create, reset}}>
        {children}
    </VisualizationContext.Provider>;
};

export const useVisualization = () => {
    return useContext(VisualizationContext);
};

function getVisualizationClass(type) {
    switch (type) {
        case VIZ_PLOT:
            return ScatterPlot;
        case 'line_chart': // also plot
            return LineChart;
        case VIZ_HISTOGRAM:
            return Histogram;
        case VIZ_PIE_CHART:
            return PieChart;
        case VIZ_TREEMAP:
            return Treemap;
        case VIZ_BAR_CHART:
            return BarChart;
        case VIZ_BOXPLOT:
            return BoxAndWhiskerPlot;
        case VIZ_UNIT_CHART:
            return UnitChart;
        default:
            console.warn(`unknown visualization type "${type}"`);
            return null;
    }
}