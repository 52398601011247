import Visualization from "./Visualization";
import XAxis from "../axes/XAxis";
import YAxis from "../axes/YAxis";
import {drawShape} from "../../../helpers/drawShape";


class Plot extends Visualization {
    attributes = {
        x: {variables: []},
        y: {variables: []},
    };

    constructor() {
        super();
        this.axes.x = new XAxis(this);
        this.axes.y = new YAxis(this);
    }

    autoUpdateStyle(records) {
        super.autoUpdateStyle(records);
        this.autoUpdateMaxRadius();
    }

    autoUpdateAxisStyle(records) {
        super.autoUpdateAxisStyle(records);
        this.axes.x.autoUpdateStyle(this.attributes.x.variables[0]);
        this.axes.y.autoUpdateStyle(this.attributes.y.variables[0]);
    }

    autoUpdateMaxRadius() {
        const areaVariable = this.attributes.area?.variables?.[0];
        this.style.maxRadius = !!areaVariable ? 30 : 2;
        this.style.maxValue = areaVariable?.maxValue ?? 1;
    }

    forEachDatapoint(callback, bounds) {
        this.records.forEach((record) => {
            const xValue = record[this.attributes.x.variables[0]?.name];
            const yValue = record[this.attributes.y.variables[0]?.name];
            const areaValue = record[this.attributes.area?.variables?.[0]?.name];
            const shapeValue = record[this.attributes.shape?.variables?.[0]?.name];
            const x = xValue?.map(this.axes.x.style.minTick, this.axes.x.style.maxTick, bounds.l, bounds.r);
            const y = yValue?.map(this.axes.y.style.minTick, this.axes.y.style.maxTick, bounds.b, bounds.t);
            const r = this.getRadiusForValue(areaValue);
            const shape = this.getShapeForValue(shapeValue);
            callback({x, y, r, shape});
        })
    }

    getRadiusForValue(value) {
        if (!this.attributes.area?.variables[0]?.name) return this.style.maxRadius;
        const {minValue, maxValue, minRadius, maxRadius} = this.style;
        const normalizedValue = (value - minValue) / (maxValue - minValue);
        return Math.sqrt(normalizedValue) * (maxRadius - minRadius) + minRadius;
    }

    getShapeForValue(value) {
        if (!this.attributes.shape?.variables[0]?.name) return this.style.shapes[0];
        const idx = this.attributes.shape?.variables[0]?.uniqueValues.findIndex(v => v === value) ?? 0;
        return this.style.shapes[idx];
    }

    drawDots(ctx, bounds) {
        this.forEachDatapoint(({x, y, r, shape}) => {
            drawShape(shape, ctx, x, y, r);
            ctx.fill();
            if (this.style.strokeWidth > 0) ctx.stroke();
        }, bounds);
    }

    validateDrawing() {
        if (this.attributes.x.variables.length === 0) return false;
        if (this.attributes.y.variables.length === 0) return false;
        return true;
    }
}

export default Plot;