import React from "react";
import Visualization from "./Visualization";
import {
    LAYOUT_GROUPED,
    LAYOUT_STACKED,
    ORIENTATION_VERTICAL,
    SCALE_CATEGORICAL
} from "../../../constants/configuration";
import Attribute from "../attributes/Attribute";
import CategoricalXAxis from "../axes/CategoricalXAxis";
import {updateInputValue} from "../../../helpers/updateInputValue";

class BoxPlot extends Visualization {
    constructor() {
        super();
        this.attributes.label = new Attribute(this, 'label', {
            isRequired: true,
            scale: [SCALE_CATEGORICAL]
        });
        this.attributes.segment = new Attribute(this, 'segment', {
            isRequired: false,
            scale: [SCALE_CATEGORICAL]
        });
        this.axes.x = new CategoricalXAxis(this);
    }

    getQuantitativeAxis() {
        if (this.style.orientation === ORIENTATION_VERTICAL) return this.axes.y;
        return this.axes.x;
    }

    autoUpdateStyle(records = this.records) {
        super.autoUpdateStyle(records);
        this.autoUpdateSpacing();
    }

    getGroupCount() {
        return this.attributes.label?.variables[0]?.uniqueValueCount ?? 1;
    }

    getSegmentCount() {
        return this.attributes.segment?.variables[0]?.uniqueValueCount ?? 1;
    }

    autoUpdateSpacing() {
        const {boxLayout, groupMarginFactor, segmentMarginFactor} = this.style;
        const bounds = this.getDatapointBounds();
        const size = (this.style.orientation === ORIENTATION_VERTICAL ? bounds.w : bounds.h);

        const groupCount = this.getGroupCount();
        const sizeWithoutAxesMargin = (size - this.style.axesMargin * 2);
        const approximateGroupWidth = sizeWithoutAxesMargin / (groupCount + (groupCount - 1) * groupMarginFactor);
        const groupMargin = Math.round(approximateGroupWidth * groupMarginFactor);

        const segmentCount = (boxLayout === LAYOUT_GROUPED ? this.getSegmentCount() : 1); // todo: if (boxLayout === STACKED) 1
        const sizeWithoutGroupMargin = sizeWithoutAxesMargin - groupMargin * (groupCount - 1);
        const approximateSegmentWidth = (sizeWithoutGroupMargin / groupCount) / (segmentCount + (segmentCount - 1) * segmentMarginFactor);
        const segmentMargin = Math.round(approximateSegmentWidth * segmentMarginFactor);
        const segmentWidth = (sizeWithoutGroupMargin - segmentMargin * groupCount * (segmentCount - 1)) / groupCount / segmentCount;

        this.style.groupMargin = groupMargin;
        this.style.segmentMargin = segmentMargin;
        this.style.segmentWidth = segmentWidth;
    }

    autoUpdateAxisStyle(records) {
        const categoricalAxis = this.style.orientation === ORIENTATION_VERTICAL
            ? this.axes.x : this.axes.y;
        categoricalAxis.autoUpdateStyle(this.attributes.label.variables[0]);
    }

    nameAttributesBasedOnOrientation(position, size) {
        if (this.style.orientation === ORIENTATION_VERTICAL) return {x: position, w: size};
        return {y: position, h: size};
    }

    forEachGroup(callback, bounds) {
        const {boxLayout} = this.style;
        const groupVariable = this.attributes.label?.variables[0];
        const segmentCount = (boxLayout === LAYOUT_GROUPED ? this.getSegmentCount() : 1); // todo: if (boxLayout === STACKED) 1
        const groupSize = (segmentCount * this.style.segmentWidth) + ((segmentCount - 1) * this.style.segmentMargin);
        const {l: x, t: y, w, h} = bounds;
        groupVariable?.uniqueValues?.forEach((group, groupIdx) => {
            const baseline = this.style.orientation === ORIENTATION_VERTICAL ? bounds.l : bounds.t;
            const groupPosition = baseline + this.style.axesMargin + groupIdx * (this.style.groupMargin + groupSize);
            const records = this.records.filter(r => r[groupVariable.name] === group);
            callback({
                x, y, w, h, group, records,
                ...this.nameAttributesBasedOnOrientation(groupPosition, groupSize)
            }, groupIdx);
        });
    }

    forEachSegment(callback, bounds, options = {}) {
        const {beforeEachGroup, afterEachGroup} = options;
        const {boxLayout, segmentWidth} = this.style;
        const isVertical = this.style.orientation === ORIENTATION_VERTICAL;
        const segmentVariable = this.attributes.segment?.variables[0];
        this.forEachGroup((group, groupIdx) => {
            if (typeof beforeEachGroup === 'function') beforeEachGroup(group, groupIdx);
            if (!segmentVariable) callback({...group, segment: null}, groupIdx, 0);
            else segmentVariable?.uniqueValues?.forEach((segment, segmentIdx) => {
                const segmentRecords = group.records.filter(r => r[segmentVariable.name] === segment);
                const segmentPosition = (isVertical ? group.x : group.y) + segmentIdx * (this.style.segmentWidth + this.style.segmentMargin);
                const obj = {...group, segment, records: segmentRecords};
                if (boxLayout === LAYOUT_GROUPED) callback({
                    ...obj, ...this.nameAttributesBasedOnOrientation(segmentPosition, segmentWidth)
                }, groupIdx, segmentIdx);
                else callback({
                    ...obj,
                },  groupIdx, segmentIdx)
            });
            if (typeof afterEachGroup === 'function') afterEachGroup(group, groupIdx);
        }, bounds);
    }

    updateOrientation(orientation) {

    }

    handleDataPointResize(dif, pos, bounds) {
        const {segment, groupMargin, segmentMargin, axesMarginLeft, axesMarginRight} = this.resizing.dataPoints;
        const value = this.style.orientation === ORIENTATION_VERTICAL ? dif.x : dif.y;
        if (axesMarginLeft || axesMarginRight)
            this.resizeAxesMargin(value * (axesMarginLeft ? 1 : -1), bounds);
        if (groupMargin)
            this.resizeGroupMargin(value, bounds);
        if (segmentMargin)
            this.resizeSegmentMargin(value, bounds);
        if (segment)
            this.resizeSegmentOrGroup(value, bounds);
        this.highlightDataPoints(this.resizing.dataPoints, bounds);
    }

    resizeAxesMargin(dif, bounds) {
        const v = (this.style.axesMargin + dif).clamp(0, bounds.w * .5);
        this.style.axesMargin = v;
        updateInputValue('datapoints-axesMargin', v);
        this.autoUpdateStyle();
    }

    resizeGroupMargin(dif, bounds) { // todo: if (boxLayout === STACKED)
        const {axesMargin, groupMargin} = this.style;
        const size = (this.style.orientation === ORIENTATION_VERTICAL ? bounds.w : bounds.h);
        const sizeWithoutAxesMargin = size - axesMargin * 2;
        const groupCount = this.getGroupCount();
        const groupMarginCount = groupCount - 1;
        const max = (sizeWithoutAxesMargin - (groupCount * this.getSegmentCount())) / groupMarginCount;
        const newGroupMargin = (groupMargin + dif).clamp(0, max);
        const newGroupWidth = (sizeWithoutAxesMargin - (newGroupMargin * groupMarginCount)) / groupCount;
        this.style.groupMarginFactor = newGroupMargin / newGroupWidth;
        this.autoUpdateStyle();
    }

    resizeSegmentMargin(dif, bounds) { // todo: if (boxLayout === STACKED)
        const {axesMargin, groupMargin} = this.style;
        const size = (this.style.orientation === ORIENTATION_VERTICAL ? bounds.w : bounds.h);
        const sizeWithoutAxesMargin = size - axesMargin * 2;
        const groupCount = this.getGroupCount();
        const groupMarginCount = groupCount - 1;
        const segmentCount = this.getSegmentCount();
        const segmentMarginCount = segmentCount - 1;
        const groupWidth = (sizeWithoutAxesMargin - (groupMargin * groupMarginCount)) / groupCount;

        const max = (groupWidth - segmentCount) / segmentMarginCount;
        const newSegmentMargin = (this.style.segmentMargin + dif).clamp(0, max);
        const newSegmentWidth = (groupWidth - (newSegmentMargin * segmentMarginCount)) / segmentCount;
        this.style.segmentMarginFactor = newSegmentMargin / newSegmentWidth;
        this.autoUpdateStyle();
    }

    resizeSegmentOrGroup(dif, bounds) {
        const {boxLayout} = this.style;
        if (boxLayout === LAYOUT_GROUPED && this.getSegmentCount() > 1)
            this.resizeSegment(dif, bounds);
        else
            this.resizeGroup(dif, bounds);
    }

    resizeSegment(dif, bounds) {
        const {axesMargin, groupMargin} = this.style;
        const size = (this.style.orientation === ORIENTATION_VERTICAL ? bounds.w : bounds.h);
        const sizeWithoutAxesMargin = size - axesMargin * 2;
        const groupCount = this.getGroupCount();
        const groupMarginCount = groupCount - 1;
        const segmentCount = this.getSegmentCount();
        const segmentMarginCount = segmentCount - 1;
        const groupWidth = (sizeWithoutAxesMargin - (groupMargin * groupMarginCount)) / groupCount;
        const max = groupWidth / segmentCount;
        const newSegmentWidth = (this.style.segmentWidth + dif).clamp(1, max);
        const newSegmentMargin = (groupWidth - (newSegmentWidth * segmentCount)) / segmentMarginCount;
        this.style.segmentMarginFactor = newSegmentMargin / newSegmentWidth;
        this.autoUpdateStyle();
    }

    // todo: summarize with resizeGroupMargin?
    resizeGroup(dif, bounds) {
        const {axesMargin} = this.style;
        const size = (this.style.orientation === ORIENTATION_VERTICAL ? bounds.w : bounds.h);
        const sizeWithoutAxesMargin = size - axesMargin * 2;
        const groupCount = this.getGroupCount();
        const groupMarginCount = groupCount - 1;
        const max = (sizeWithoutAxesMargin - (groupMarginCount)) / groupCount;
        const newSegmentWidth = (this.style.segmentWidth + dif).clamp(1, max);
        const newGroupMargin = (sizeWithoutAxesMargin - (newSegmentWidth * groupCount)) / groupMarginCount;
        this.style.groupMarginFactor = newGroupMargin / newSegmentWidth;
        this.autoUpdateStyle();
    }

    checkDataPointHover(pos, bounds) {
        const isHoveringBounds = pos.x > bounds.l && pos.x <= bounds.r && pos.y > bounds.t && pos.y <= bounds.b;
        const axesMarginLeft = isHoveringBounds && this.checkAxesMarginLeftHover(pos, bounds);
        const axesMarginRight = isHoveringBounds && this.checkAxesMarginRightHover(pos, bounds);
        const axesMargin = axesMarginLeft || axesMarginRight;
        const groupMargin = !axesMargin && this.checkGroupMarginHover(pos, bounds);
        const segmentMargin = !axesMargin && !groupMargin && this.checkSegmentMarginHover(pos, bounds);
        const segment = isHoveringBounds && !axesMargin && !groupMargin && !segmentMargin;
        return {segment, groupMargin, segmentMargin, axesMarginLeft, axesMarginRight};
    }

    checkAxesMarginLeftHover(pos, bounds) {
        const {axesMargin} = this.style;
        if (this.style.orientation === ORIENTATION_VERTICAL) {
            if (pos.x <= bounds.l + axesMargin) return true;
        } else {
            if (pos.y <= bounds.t + axesMargin) return true;
        }
        return false;
    }


    checkAxesMarginRightHover(pos, bounds) {
        const {axesMargin} = this.style;
        if (this.style.orientation === ORIENTATION_VERTICAL) {
            if (pos.x > bounds.r - axesMargin) return true;
        } else {
            if (pos.y > bounds.b - axesMargin) return true;
        }
        return false;
    }

    checkGroupMarginHover(pos, bounds) {
        const {groupMargin} = this.style;
        let hoveringAny = false;
        this.forEachGroup(({x, y, w, h}, i) => {
            if (i === 0 || hoveringAny) return;
            if (this.style.orientation === ORIENTATION_VERTICAL) {
                if (pos.x > x - groupMargin && pos.x <= x && pos.y > y && pos.y <= y + h)
                    hoveringAny = true;
            } else {
                if (pos.x > x && pos.x <= x + w && pos.y > y - groupMargin && pos.y <= y)
                    hoveringAny = true;
            }
        }, bounds);
        return hoveringAny;
    }

    checkSegmentMarginHover(pos, bounds) {
        const {boxLayout, segmentMargin} = this.style;
        if (boxLayout === LAYOUT_STACKED) return false;
        let hoveringAny = false;
        this.forEachSegment(({x, y, w, h}, gi, si) => {
            if (si === 0) return;
            if (this.style.orientation === ORIENTATION_VERTICAL) {
                if (pos.x > x - segmentMargin && pos.x <= x && pos.y > y && pos.y <= y + h)
                    hoveringAny = true;
            } else {
                if (pos.x > x && pos.x <= x + w && pos.y > y - segmentMargin && pos.y <= y)
                    hoveringAny = true;
            }
        }, bounds);
        return hoveringAny;
    }

    highlightDataPoints(obj, bounds) {
        const {segment, groupMargin, segmentMargin, axesMarginLeft, axesMarginRight} = obj;
        if (axesMarginLeft || axesMarginRight)
            this.highlightAxesMargin(bounds);
        if (groupMargin)
            this.highlightGroupMargin(bounds);
        if (segmentMargin)
            this.highlightSegmentMargin(bounds);
        if (segment)
            this.highlightSegments(bounds);
    }

    highlightAxesMargin(bounds) {
        const ctx = this.sgfCtx;
        const {axesMargin} = this.style;
        if (this.style.orientation === ORIENTATION_VERTICAL) {
            ctx.fillRect(bounds.l, bounds.t, axesMargin, bounds.h);
            ctx.fillRect(bounds.r - axesMargin, bounds.t, axesMargin, bounds.h);
        } else {
            ctx.fillRect(bounds.l, bounds.t, bounds.w, axesMargin);
            ctx.fillRect(bounds.l, bounds.b - axesMargin, bounds.w, axesMargin);
        }
    }

    highlightGroupMargin(bounds) {
        const ctx = this.sgfCtx;
        const {groupMargin} = this.style;
        this.forEachGroup(({x, y, w, h}, i) => {
            if (i === 0) return;
            if (this.style.orientation === ORIENTATION_VERTICAL)
                ctx.fillRect(x - groupMargin, y, groupMargin, h);
            else
                ctx.fillRect(x, y - groupMargin, w, groupMargin);
        }, bounds);
    }

    highlightSegmentMargin(bounds) {
        const ctx = this.sgfCtx;
        const {segmentMargin} = this.style;
        this.forEachSegment(({x, y, w, h}, gi, si) => {
            if (si === 0) return;
            if (this.style.orientation === ORIENTATION_VERTICAL)
                ctx.fillRect(x - segmentMargin, y, segmentMargin, h);
            else
                ctx.fillRect(x, y - segmentMargin, w, segmentMargin);
        }, bounds);
    }

    highlightSegments(bounds) {
        const ctx = this.sgfCtx;
        this.forEachSegment(({x, y, w, h}) => {
            ctx.fillRect(x, y, w, h);
        }, bounds);
    }
}

export default BoxPlot;