import Axis from "./Axis";
import {FACTORS, POSITION_START} from "../../../constants/configuration";
import {updateInputValue} from "../../../helpers/updateInputValue";

class YAxis extends Axis {
    constructor(viz, id, name, options = {}) {
        super(viz, id ?? 'y', name ?? 'y-axis', options);

        this.style.title = 'y-axis';
        this.style.labelBaseline = 'middle'
        this.style.titleBaseline = 'bottom';
        this.style.titleRotate = -90;

        this.style.position = POSITION_START;
        this.style.labelTextAlign = 'right';
    }

    getAxisPosition(bounds) {
        return this.style.position === POSITION_START ? bounds.l : bounds.r;
    }

    modifyDatapointBounds(bounds) {
        const w = this.getTotalSize();
        let modifiedRect = {...bounds};
        modifiedRect.w -= w;
        if (this.style.position === POSITION_START) modifiedRect.l += w;
        else modifiedRect.r -= w;
        return modifiedRect;
    }

    getBounds(bounds) {
        const w = this.getTotalSize();
        let modifiedBounds = {...bounds};
        modifiedBounds.w = w;
        if (this.style.position === POSITION_START) modifiedBounds.r = modifiedBounds.l + w;
        else modifiedBounds.l = modifiedBounds.r - w;
        return modifiedBounds;
    }

    forEachTick(ctx, bounds, callback, options = {}) {
        const min = options?.min ?? this.style.minTick;
        const max = options?.max ?? this.style.maxTick;
        const interval = options?.interval ?? this.style.tickInterval;
        const x = this.getAxisPosition(bounds);
        for (let tick = min; tick <= max; tick += interval) {
            const y = tick.map(min, max, bounds.b, bounds.t);
            callback(x, y, tick / this.style.tickFactor);
        }
    }

    drawGrid(ctx, bounds) {
        if (!this.style.showGrid) return;
        this.applyGridStyles(ctx);
        ctx.beginPath();
        this.forEachTick(ctx, bounds, (x, y) => {
            ctx.moveTo(bounds.l, y);
            ctx.lineTo(bounds.r, y);
        }, {interval: this.style.gridInterval});
        ctx.stroke();
    }

    drawTicks(ctx, bounds) {
        ctx.beginPath();
        this.applyTickStyles(ctx);
        this.forEachTick(ctx, bounds, (x, y) => {
            ctx.moveTo(x, y);
            ctx.lineTo(x + (this.style.tickLength * this.getOffsetModifier()), y);
        });
        const x = this.getAxisPosition(bounds);
        ctx.moveTo(x, bounds.t);
        ctx.lineTo(x,  bounds.b);
        ctx.stroke();
    }

    drawTitle(ctx, bounds) {
        ctx.save();
        this.applyTitleStyles(ctx);
        const x = this.getAxisPosition(bounds);
        const xOffset = this.getTitleOffset();
        ctx.translate(x + xOffset, bounds.t + bounds.h * .5);
        ctx.rotate(this.style.titleRotate * (Math.PI / 180));
        ctx.fillText(this.getTitle(), 0, 0);
        ctx.restore();
    }

    drawLabels(ctx, bounds) {
        this.applyLabelStyles(ctx);
        this.forEachTick(ctx, bounds, (x, y, value) => {
            const text = this.style.prefix + value + this.style.suffix;
            const xOffset = this.getLabelOffset();
            ctx.fillText(text, x + xOffset, y);
        });
    }

    updatePosition(v) {
        const st = v === POSITION_START;
        this.style.position = v;
        this.style.labelTextAlign = st ? 'right' : 'left';
        this.style.titleRotate = st ? -90 : 90;
    }

    checkHover(pos, bounds) {
        const baselineX = this.style.position === POSITION_START ? bounds.l : bounds.r; // tb = lr
        const x2 = baselineX + this.getTotalSize() * this.getOffsetModifier();
        const hovering = {title: false, titleMargin: false, label: false, labelMargin: false, ticks: false};
        const isHoveringY = pos.y > bounds.t && pos.y <= bounds.b;
        const isHoveringX = pos.x > Math.min(baselineX, x2) && pos.x <= Math.max(baselineX, x2);
        if (!isHoveringX || !isHoveringY) return hovering;
        if (this.checkHoverTitle(pos.x, baselineX))
            return {...hovering, title: true};
        if (this.checkHoverTitleMargin(pos.x, baselineX))
            return {...hovering, titleMargin: true};
        if (this.checkHoverLabel(pos.x, baselineX))
            return {...hovering, label: true};
        if (this.checkHoverLabelMargin(pos.x, baselineX))
            return {...hovering, labelMargin: true};
        if (this.checkHoverTicks(pos.x, baselineX))
            return {...hovering, ticks: true};
        return hovering;
    }

    highlightTitle(ctx, bounds) {
        const w = this.style.titleSize;
        const x = (this.style.position === POSITION_START ? bounds.l - w : bounds.r) + this.getTitleOffset();
        ctx.fillRect(x, bounds.t, w, bounds.h);
    }

    highlightTitleMargin(ctx, bounds) {
        const w = this.style.titleMargin;
        const x = (this.style.position === POSITION_START ? bounds.l - w : bounds.r) + this.getTitleMarginOffset();
        ctx.fillRect(x, bounds.t, w, bounds.h);
    }

    highlightLabel(ctx, bounds) {
        const w = this.style.labelSize;
        const x = (this.style.position === POSITION_START ? bounds.l - w : bounds.r) + this.getLabelOffset();
        ctx.fillRect(x, bounds.t, w, bounds.h);
    }

    highlightLabelMargin(ctx, bounds) {
        const w = this.style.labelMargin;
        const x = (this.style.position === POSITION_START ? bounds.l - w : bounds.r) + this.getLabelMarginOffset();
        ctx.fillRect(x, bounds.t, w, bounds.h);
    }

    highlightTicks(ctx, bounds) {
        const w = this.style.tickLength;
        const x = (this.style.position === POSITION_START ? bounds.l - w : bounds.r);
        ctx.fillRect(x, bounds.t, w, bounds.h);
    }

    handleResize(dif, datapointBounds) {
        this.highlight(this.resizing, datapointBounds);
        const {title, titleMargin, label, labelMargin, ticks} = this.resizing;
        const f = this.style.position === POSITION_START ? 1 : -1
        if (title) this.resizeTitle(dif.x * f);
        if (titleMargin) this.resizeTitleMargin(dif.x * f);
        if (label) this.resizeLabel(dif.x * f);
        if (labelMargin) this.resizeLabelMargin(dif.x * f);
        if (ticks) this.resizeTicks(dif.x * f);
    }

    updateLabelSize(ctx = this.visualization.ctx) {
        if (!ctx) return;
        this.applyLabelStyles(ctx);
        const m = ctx.measureText(`${this.style.maxTick / this.style.tickFactor}`);
        this.style.labelSize = m.width;

    }
}

export default YAxis;