
export const Button = (props) => {
    const {
        children,
        onClick,
        theme = 'primary',
        className = ''
    } = props;

    return <button
        className={`Button Button--theme-${theme} ${className}`}
        onClick={onClick}>
        {children}
    </button>
};