import {FileIcon, TimesIcon} from "../iconography";
import cross from "../../assets/icons/cross.svg";

export const FileName = (props) => {
    const {fileName, onRemove = () => null} = props;
    return <div className={'File'}>
        <div className={'Icon Icon--size-sm'}>
            <FileIcon/>
        </div>
        <p className={'File-Name'}>
            {fileName}
        </p>
        <button
            onClick={onRemove}
            className={'Button Button--theme-icon'}>
            <div className={'Icon Icon--size-xs'}>
                <TimesIcon/>
            </div>
        </button>
    </div>;
};
export const LargeFileInput = (props) => {
    const {
        id, value, fileName, accept, disabled = false, className = "",
        onChange = (v) => null, multiple = false
    } = props;

    return <>
        <label
            htmlFor={id}
            className={'Button Button--span Button--theme-' + (fileName ? 'secondary' : 'primary')}
            // className={`Input Input--type-file ${fileName ? 'Input--type-file--change' : ''} ${className}`}
        >{fileName ? "Change" : "Select"} File</label>
        <input
            id={id}
            className={'--display-none'}
            type={'file'}
            multiple={multiple}
            accept={accept}
            disabled={disabled}
            onChange={(e) => onChange(e.target.files)}
        />
    </>;
};
export const DragAndDropFileInput = (props) => {
    const {id, value, className = "", onChange = (v) => null, multiple = false, accept, disabled} = props;

    const dropHandler = (e) => {
        // prevent file from being opened by the browser
        e.preventDefault();
        if (e.dataTransfer.items) {
            const item = e.dataTransfer.items[0];
            if (item.kind !== 'file') return;
            const file = item.getAsFile();
            onChange([file]);
        } else {
            const file = e.dataTransfer.files[0];
            onChange([file]);
        }
    };

    return <label
        htmlFor={id}
        className={`DropFileInput ${className}`}
        onDragOver={(e) => e.preventDefault()}
        onDrop={dropHandler}
    >
        <input
            id={id}
            className={'--display-none'}
            type={'file'}
            multiple={multiple}
            accept={accept}
            disabled={disabled}
            onChange={(e) => onChange(e.target.files)}
        />
        <p className={'DropFileInput-Label'}>drag & drop a file here or <span className={'--underscore'}>browse</span>
        </p><br/>
        <p className={'DropFileInput-Caption'}>accepts csv, tsv, and txt files</p>
    </label>;
};