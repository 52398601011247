import {InspectorGroup} from "../../input/Inspector";
import {SCALE_CATEGORICAL, SCALE_QUANTITATIVE, SHAPE_OPTIONS} from "../../../constants/configuration";
import Plot from "../core/Plot";
import Attribute from "../attributes/Attribute";
import {NumberInputGroup} from "../../input/NumberInput";
import {ColorInputGroup} from "../../input/ColorInput";
import {SelectGroup} from "../../input/Select";

class ScatterPlot extends Plot {

    constructor() {
        super();
        this.attributes.x = new Attribute(this, 'x', {
            isRequired: true,
            scale: [SCALE_QUANTITATIVE]
        });
        this.attributes.y = new Attribute(this, 'y', {
            isRequired: true,
            scale: [SCALE_QUANTITATIVE]
        });
        this.attributes.area = new Attribute(this,'area', {
            scale: [SCALE_QUANTITATIVE],
            unassignAttributes: ['shape']
        });
        this.attributes.shape = new Attribute(this,'shape', {
            scale: [SCALE_CATEGORICAL],
            unassignAttributes: ['area']
        });
    }

    drawDataPoints(ctx, bounds) {
        this.applyDataPointStyles(ctx);
        this.drawDots(ctx, bounds);
    }

    DataPointsInspectorGroup = () => {
        return (
            <InspectorGroup
                id={`${this.id}`}
                title={'Data Points'}>
                <ColorInputGroup
                    id={`${this.id}-fillColors`}
                    label={'Color'}
                    defaultValue={this.style.fillColors[0]}
                    onBlur={v => {
                        this.style.fillColors[0] = v;
                        this.onChange();
                    }}/>
                <ColorInputGroup
                    id={`${this.id}-strokeColors`}
                    label={'Stroke'}
                    defaultValue={this.style.strokeColors[0]}
                    onBlur={v => {
                        this.style.strokeColors[0] = v;
                        this.onChange();
                    }}/>
                <NumberInputGroup
                    id={`${this.id}-strokeWidth`}
                    label={'Stroke Width'}
                    defaultValue={this.style.strokeWidth}
                    onBlur={v => {
                        this.style.strokeWidth = v;
                        this.onChange();
                    }}
                />
                {/*<SelectGroup*/}
                {/*    id={`${this.id}-shape`}*/}
                {/*    label={'Shape'}*/}
                {/*    defaultValue={this.style.shape}*/}
                {/*    options={SHAPE_OPTIONS}*/}
                {/*    onChange={v => {*/}
                {/*        this.style.shape = v;*/}
                {/*        this.onChange();*/}
                {/*    }}/>*/}
                <NumberInputGroup
                    id={`${this.id}-radius`}
                    label={'Radius'}
                    defaultValue={this.style.maxRadius}
                    onBlur={v => {
                        this.style.maxRadius = v;
                        this.onChange();
                    }}/>
            </InspectorGroup>
        )
    }
}

export default ScatterPlot;