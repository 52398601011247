import {ERROR_CODES, ERROR_TYPES} from "../constants/data";

const ErrorMessages = (props) => {
    const {errors} = props;
    if (errors?.length > 0) return <div className={'ParsingError'}>
        <DelimiterError errors={errors}/>
        <QuotesError errors={errors}/>
        <FieldError errors={errors}/>
    </div>
    return null;
};

const DelimiterError = (props) => {
    const {errors} = props;
    const delimiterError = errors.some((error) => error.type === ERROR_TYPES.DELIMITER);
    if (delimiterError) return <p className={'ParsingError-Paragraph --color-warning'}>
        Delimiter could not be detected, defaulting to ",". If necessary, manually select one from the "Advanced Options" below.
    </p>;
    return null;
};

const FieldError = (props) => {
    const {errors} = props;
    const fieldErrors = errors.filter((error) => error.type === ERROR_TYPES.FIELDS);
    if (fieldErrors.length > 0) return <p className={'ParsingError-Paragraph --color-warning'}>
        {fieldErrors.length === 1
            ? `Too ${fieldErrors[0].code === ERROR_CODES.TOO_FEW_FIELDS ? 'few' : 'many'} fields in row ${fieldErrors[0].row}.`
            : `Too few or too many fields in ${fieldErrors.length} rows.`}
        Each row should have an equal number of fields.
    </p>
    return null;
};

const QuotesError = (props) => {
    const {errors} = props;
    const quoteErrors = errors.filter((error) => error.type === ERROR_TYPES.QUOTES);
    if (quoteErrors.length > 0) return <p className={'ParsingError-Paragraph --color-warning'}>
        {quoteErrors.length === 1
            ? `Invalid quotes in row ${quoteErrors[0].row}.`
            : `Invalid quotes in ${quoteErrors.length} places.`}
    </p>;
    return null;
};

export default ErrorMessages;