import {Link} from "react-router-dom";
import {DELIMITERS, LINEBREAKS} from "../../constants/data";
import {InputGroup} from "../input/InputGroup";
import ErrorMessages from "../ErrorMessages";
import Table from "../Table";
import {useDataHandler} from "../../context/DataContext";
import {InspectorGroup} from "../input/Inspector";
import {Select} from "../input/Select";
import {DragAndDropFileInput, FileName, LargeFileInput} from "../input/FileInput";
import {useVisualization} from "../../context/VisualizationContext";
import {useRecommendations} from "../../context/RecommendationContext";

const DataPanel = () => {
    const dataHandler = useDataHandler();
    const vizHandler = useVisualization();
    const recHandler = useRecommendations();
    const onInput = (files) => {
        dataHandler.readFile(files[0]);
        vizHandler.reset();
        recHandler.reset();
    }

    function removeFile() {
        dataHandler.removeFile();
        vizHandler.reset();
        recHandler.reset();
    }

    return <>
        <div className={'Pane Pane--inspector'}>
            <div className={'Inspector --layer-1'}>
                <div className={'Inspector-Body'}>

                    <InspectorGroup title={'Data Source'}>
                        {dataHandler.file.name
                            && <FileName
                                fileName={dataHandler.file.name}
                                onRemove={removeFile}/>}
                        <ErrorMessages errors={dataHandler.file.errors}/>

                        <LargeFileInput
                            id={'file-upload'}
                            fileName={dataHandler.file.name}
                            type={'file'}
                            accept={'text/csv,text/tsv,text/plain,text/tab-separated-values'}
                            onChange={onInput}
                        />
                    </InspectorGroup>

                    <InspectorGroup title={'Advanced Options'}>
                        <InputGroup id={'select-delimiter'} label={'Delimiters'}>
                            <Select
                                id={'select-delimiter'}
                                options={!dataHandler.file.detectedDelimiter ? DELIMITERS : DELIMITERS.map(d => (
                                    !!d.value ? d : {...d, name: `${dataHandler.file.detectedDelimiter} (Auto-Detect)`}
                                ))}
                                value={dataHandler.file.selectedDelimiter}
                                onChange={dataHandler.selectDelimiter}/>
                        </InputGroup>
                        <InputGroup id={'select-linebreak'} label={'Linebreaks'}>
                            <Select
                                id={'select-linebreak'}
                                options={!dataHandler.file.detectedLinebreak ? LINEBREAKS : LINEBREAKS.map(d => (
                                    !!d.value ? d : {...d, name: `${dataHandler.file.detectedLinebreak
                                            .replace('\n', '\\n')
                                            .replace('\r', '\\r')
                                        } (Auto-Detect)`}
                                ))}
                                value={dataHandler.file.selectedLinebreak}
                                onChange={dataHandler.selectLinebreak}/>
                        </InputGroup>
                    </InspectorGroup>

                </div>
            </div>

            <Link to={'/visualize'} className={'Button Button--theme-primary Button--span Button--margin-top'
                + (dataHandler.file.name ? '' : ' Button--theme-secondary')}>
                {dataHandler.file.name ? 'Visualize Data' : 'Browse Visualizations'}
            </Link>

        </div>
        <div className={'Pane Pane--grow --layer-0 --padding-t' + (!dataHandler.file.name ? ' --padding-b' : '')}>
            <div className={'Workspace'}>
            {!dataHandler.file.name
                ? (
                    <DragAndDropFileInput
                            id={'dragAndDrop'}
                            onChange={onInput}/>
                ) : (
                    <div className={'ScrollArea ScrollArea--xy'}>
                        <Table/>
                    </div>
                )}
            </div>
        </div>
    </>;
};

export default DataPanel;