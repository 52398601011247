import React, {useState} from "react";
import ReactDOM from 'react-dom';
import {InspectorGroup} from "../../input/Inspector";
import {parseColor} from "../../../helpers/color";
import {DARK_COLOR, DEFAULT_COLORS, LIGHT_COLOR} from "../../../constants/colors";
import {
    INTERACTION_COLOR, LAYOUT_GROUPED,
    ORIENTATION_VERTICAL,
    SHAPE_CIRCLE,
    SHAPE_CROSS,
    SHAPE_PLUS,
    SHAPE_SQUARE,
    SHAPE_TRIANGLE,
    SORTING_DESCENDING,
    TREEMAP_SLICED, TREEMAP_SQUARIFIED
} from "../../../constants/configuration";
import {updateInputValue} from "../../../helpers/updateInputValue";
import {NumberInputGroup} from "../../input/NumberInput";
import {ColorInputGroup} from "../../input/ColorInput";

class Visualization {
    id = 'datapoints';
    ctx = null;
    sgfCtx = null;
    records = [];
    attributes = {};

    dimensions = {w: 460, h: 300};
    axes = {};
    style = {
        margin: {t: 20, r: 20, b: 20, l: 20},
        backgroundColor: {opacity: 0, hex: '#ffffff', rgb: [255, 255, 255], hsv: [360, 0, 100]},
        fillColors: [...DEFAULT_COLORS],
        strokeColors: [...DEFAULT_COLORS],
        strokeWidth: 2,
        // labels
        labelFillColor: LIGHT_COLOR,
        labelStrokeColor: DARK_COLOR,
        labelStrokeWidth: 0,
        labelFontFamily: 'sans-serif',
        labelFontSize: 16,
        labelTextAlign: 'center',
        labelBaseline: 'middle',
        // plot
        shapes: [SHAPE_CIRCLE, SHAPE_TRIANGLE, SHAPE_SQUARE, SHAPE_PLUS, SHAPE_CROSS],
        minRadius: 0,
        maxRadius: 3,
        minValue: 0,
        maxValue: 1,
        // pie chart
        outerRadius: 100,
        innerRadius: 50,
        angleMargin: 0,
        // treemap
        layout: TREEMAP_SQUARIFIED,
        // bars or boxes
        axesMargin: 8,
        segmentWidth: 8,
        segmentMargin: 0,
        segmentMarginFactor: .1,
        groupMargin: 8,
        groupMarginFactor: .5,
        orientation: ORIENTATION_VERTICAL,
        sorting: SORTING_DESCENDING,
        boxLayout: LAYOUT_GROUPED,
        // box plot
        showStrip: false,
        showViolin: true,
        showBoxAndWhisker: true,
        showOutliers: true,
        violinStrokeWidth: 0,
        violinFillColor: {...DEFAULT_COLORS[0], opacity: 30},
        violinStrokeColor: DEFAULT_COLORS[0],
        violinWidth: 50,
        violinMaxDensity: 1,
        whiskerLength: 8,
        boxFillColor: {...DEFAULT_COLORS[0], opacity: 30},
        boxStrokeColor: DARK_COLOR,
        boxStrokeWidth: 1,
        dotFillColor: DEFAULT_COLORS[0],
        dotStrokeColor: DEFAULT_COLORS[0],
        dotStrokeWidth: 0,
        outlierThreshold: 1.5,
        // unit chart
        unitCols: 3,
        unitRows: 10,
    };

    resizing = {
        any: false,
        margin: {t: false, r: false, b: false, l: false},
        dataPoints: {}
    }

    prevPos = {x: 0, y: 0};

    warnings = {invalidAttributes: 'Not enough attributes are assigned a variable.'};

    bounds = {
        datapoints: {l: 0, r: 0, t: 0, b: 0, w: 0, h: 0}
    }

    constructor() {
    }

    // todo
    translateMapToAttributes(map, variables) {
        Object.entries(map)?.forEach(([key, value]) => {
            if (!this.attributes.hasOwnProperty(key)) return;
            this.attributes[key].variables = [variables.find(v => v.name === value)];
        });
        this.autoUpdateStyle();
    }

    getAttributesAsArray() {
        return Object.values(this.attributes);
    }

    assignVariableToAttribute(variable, attributeKey) {
        if (!variable) return;
        Object.entries(this.attributes).forEach(([key, attribute]) => {
            if (attribute.variables.some(v => v.name === variable.name))
                this.unassignVariableFromAttribute(variable, key);
        });
        if (!this.attributes.hasOwnProperty(attributeKey)) return;
        this.attributes[attributeKey].unassignAttributes?.forEach(key => {
            if (!this.attributes.hasOwnProperty(key)) return;
            this.attributes[key].variables = [];
        });
        this.attributes[attributeKey].variables = [variable];
        this.autoUpdateStyle(this.records);
    }

    unassignVariableFromAttribute(variable, attributeKey) {
        if (!variable || !this.attributes.hasOwnProperty(attributeKey)) return;
        const filteredVariables = this.attributes[attributeKey].variables.filter(v => v.name !== variable.name);
        this.attributes[attributeKey].variables = filteredVariables;
        this.autoUpdateStyle(this.records);
    }

    forEachAxis(callback) {
        Object.entries(this.axes).forEach(([key, axis]) => {
            callback(key, axis);
        });
    }

    autoUpdateStyle(records = this.records) {
        this.autoUpdateAxisStyle(records);
    }

    autoUpdateAxisStyle(records) {

    }

    updateData(records) {
        this.records = records;
        this.autoUpdateStyle(records);
        this.draw();
    }

    updateCtx(ctx, sgfCtx) {
        this.ctx = ctx;
        this.sgfCtx = sgfCtx;
        sgfCtx.fillStyle = INTERACTION_COLOR;
        this.autoUpdateStyle();
    }

    getAxesBounds() {
        const bounds = {
            t: this.style.margin.t,
            l: this.style.margin.l,
            b: this.dimensions.h - this.style.margin.b,
            r: this.dimensions.w - this.style.margin.r,
        };
        bounds.w = bounds.r - bounds.l;
        bounds.h = bounds.b - bounds.t;
        return bounds;
    }

    getDatapointBounds() {
        let bounds = this.getAxesBounds();
        this.forEachAxis((key,axis) => bounds = axis.modifyDatapointBounds(bounds));
        return bounds;
    }

    debugBounds(ctx, bounds) {
        ctx.strokeStyle = 'rgba(0, 0, 0, .1)';
        ctx.strokeRect(bounds.l, bounds.t, bounds.w, bounds.h);
    }

    drawAxes(ctx, bounds) {
        this.forEachAxis((key, axis) => axis.draw(ctx, bounds));
    }

    applyDataPointStyles(ctx, i = 0) {
        const fallbackFillColor = this.style.fillColors[0];
        const fallbackStrokeColor = this.style.strokeColors[0];
        ctx.fillStyle = parseColor(this.style.fillColors[i] ?? fallbackFillColor);
        ctx.strokeStyle = parseColor(this.style.strokeColors[i] ?? fallbackStrokeColor);
        ctx.lineWidth = this.style.strokeWidth;
    }

    applyLabelStyles(ctx) {
        ctx.textAlign = this.style.labelTextAlign;
        ctx.textBaseline = this.style.labelBaseline;
        ctx.font = `${this.style.labelFontSize}px ${this.style.labelFontFamily}`;
        ctx.lineWidth = this.style.labelStrokeWidth;
        ctx.strokeStyle = parseColor(this.style.labelStrokeColor);
        ctx.fillStyle = parseColor(this.style.labelFillColor);
    }

    drawDataPoints(ctx, bounds) {

    }

    validateDrawing() {
        return !Object.values(this.attributes).some(a =>
            a.isRequired && a.variables.length === 0
        );
    }

    drawBackground(ctx) {
        ctx.clearRect(0, 0, this.dimensions.w, this.dimensions.h);
        ctx.fillStyle = parseColor(this.style.backgroundColor);
        ctx.fillRect(0, 0, this.dimensions.w, this.dimensions.h);
    }

    draw() {
        const ctx = this.ctx;
        if (!ctx) return;
        this.drawBackground(ctx);
        if (!this.validateDrawing()) {
            this.drawWarning(ctx);
            return;
        }
        const axesBounds = this.getDatapointBounds();
        this.drawAxes(ctx, axesBounds);
        this.drawDataPoints(ctx, axesBounds);
    }

    drawWarning(ctx) {
        ctx.fillStyle = '#777777';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.font = 'sans-serif 16px';
        ctx.fillText(this.warnings.invalidAttributes, this.dimensions.w * .5, this.dimensions.h * .5);
    }

    updateDimensions({w, h}) {
        this.dimensions = {w, h};
        const worksheet = document.getElementById('worksheet');
        worksheet.style.width = `${w}px`;
        worksheet.style.height = `${h}px`;
        ['worksheet-visualize', 'worksheet-overlay'].forEach(id => {
            const el = document.getElementById(id);
            el.width = w;
            el.height = h;
        });
        this.draw();
    }

    checkMarginHover(pos) {
        const isHoveringX = pos.x > 0 && pos.x <= this.dimensions.w;
        const isHoveringY = pos.y > 0 && pos.y <= this.dimensions.h;
        const t = isHoveringX && pos.y > 0 && pos.y <= this.style.margin.t;
        const b = isHoveringX && pos.y > this.dimensions.h - this.style.margin.b && pos.y <= this.dimensions.h;
        const l = isHoveringY && pos.x > 0 && pos.x <= this.style.margin.l;
        const r = isHoveringY && pos.x > this.dimensions.w - this.style.margin.r && pos.x <= this.dimensions.w;
        return {t, b, l, r};
    }

    handleMarginHover(pos) {
        const hovering = this.checkMarginHover(pos);
        this.highlightMargins(hovering);
    }

    getDifference(pos2, pos1 = this.prevPos) {
        return {x: pos2.x - pos1.x, y: pos2.y - pos1.y};
    }

    handleMarginResize(dif) {
        const {t, b, l, r} = this.resizing.margin;
        if (t) this.resizeMarginTop(dif);
        if (b) this.resizeMarginBottom(dif);
        if (l) this.resizeMarginLeft(dif);
        if (r) this.resizeMarginRight(dif);
        this.highlightMargins({t, b, l, r});
    }

    resizeMarginTop(dif) {
        const v = (this.style.margin.t + dif.y).clamp(0, Math.round(this.dimensions.h * .5));
        this.style.margin.t = v;
        updateInputValue('margin-top', v);
        this.autoUpdateStyle();
    }

    resizeMarginBottom(dif) {
        const v = (this.style.margin.b - dif.y).clamp(0, Math.round(this.dimensions.h * .5));
        this.style.margin.b = v;
        updateInputValue('margin-bottom', v);
        this.autoUpdateStyle();
    }

    resizeMarginLeft(dif) {
        const v = (this.style.margin.l + dif.x).clamp(0, Math.round(this.dimensions.w * .5));
        this.style.margin.l = v;
        updateInputValue('margin-left', v);
        this.autoUpdateStyle();
    }

    resizeMarginRight(dif) {
        const v = (this.style.margin.r - dif.x).clamp(0, Math.round(this.dimensions.w * .5));
        this.style.margin.r = v;
        updateInputValue('margin-right', v);
        this.autoUpdateStyle();
    }

    highlightMargins({t, b, l, r}) {
        const ctx = this.sgfCtx;
        if (!ctx) return;
        if (t) this.highlightMarginTop(ctx);
        if (b) this.highlightMarginBottom(ctx);
        if (l) this.highlightMarginLeft(ctx);
        if (r) this.highlightMarginRight(ctx);
    }

    highlightMarginTop(ctx) {
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(this.dimensions.w, 0);
        ctx.lineTo(this.dimensions.w - this.style.margin.r, this.style.margin.t);
        ctx.lineTo(this.style.margin.l, this.style.margin.t);
        ctx.fill();
    }

    highlightMarginBottom(ctx) {
        ctx.beginPath();
        ctx.moveTo(this.style.margin.l, this.dimensions.h - this.style.margin.b)
        ctx.lineTo(this.dimensions.w - this.style.margin.r, this.dimensions.h - this.style.margin.b);
        ctx.lineTo(this.dimensions.w, this.dimensions.h);
        ctx.lineTo(0, this.dimensions.h);
        ctx.fill();
    }

    highlightMarginLeft(ctx) {
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(this.style.margin.l, this.style.margin.t);
        ctx.lineTo(this.style.margin.l, this.dimensions.h - this.style.margin.b);
        ctx.lineTo(0, this.dimensions.h);
        ctx.fill();
    }

    highlightMarginRight(ctx) {
        ctx.beginPath();
        ctx.moveTo(this.dimensions.w - this.style.margin.r, this.style.margin.t);
        ctx.lineTo(this.dimensions.w, 0);
        ctx.lineTo(this.dimensions.w, this.dimensions.h);
        ctx.lineTo(this.dimensions.w - this.style.margin.r, this.dimensions.h - this.style.margin.b);
        ctx.fill();
    }

    handleDataPointHover(pos) {
        const bounds = this.getDatapointBounds();
        const hovering = this.checkDataPointHover(pos, bounds);
        this.highlightDataPoints(hovering, bounds);
    }

    handleDataPointResize(dif, pos) {

    }

    checkDataPointHover(pos, bounds) {
    }

    highlightDataPoints(obj, bounds) {
    }

    handleHover(pos) {
        this.handleMarginHover(pos);
        this.handleDataPointHover(pos);
        const axesBounds = this.getDatapointBounds();
        this.forEachAxis((key, axis) => {
            axis.handleHover(pos, axesBounds);
        });
    }

    handleResize(pos) {
        const dif = this.getDifference(pos);
        this.handleMarginResize(dif);
        const axesBounds = this.getDatapointBounds();
        this.handleDataPointResize(dif, pos, axesBounds);
        this.forEachAxis((key, axis) => {
            axis.handleResize(dif, axesBounds);
        });
        this.draw();
        this.prevPos = pos;
    }

    clearHighlight(ctx = this.sgfCtx) {
        if (!ctx) return;
        ctx.clearRect(0, 0, this.dimensions.w, this.dimensions.h);
        ctx.fillStyle = INTERACTION_COLOR;
    }

    onMouseMove(pos) {
        this.clearHighlight();
        if (!this.validateDrawing()) return;
        if (this.resizing.any) this.handleResize(pos);
        else this.handleHover(pos);
    }

    startResize(pos) {
        this.prevPos = pos;
        this.resizing.any = true;
        this.resizing.margin = this.checkMarginHover(pos);
        const axesBounds = this.getDatapointBounds();
        this.resizing.dataPoints = this.checkDataPointHover(pos, axesBounds);
        this.forEachAxis((key, axis) => {
            axis.startResize(pos, axesBounds);
        });
    }

    onMouseDown(pos) {
        if (!this.validateDrawing()) return;
        this.startResize(pos);
    }

    onMouseUp(pos) {
        if (!this.validateDrawing()) return;
        this.resizing.any = false;
        this.resizing.margin = {t: false, b: false, l: false, r: false};
    }

    WorksheetInspectorGroup = ({selectedGroup, setSelectedGroup}) => {
        return (
            <InspectorGroup
                id={'worksheet'}
                title={'Worksheet'}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}>
                <NumberInputGroup
                    id={'width'}
                    label={'Width'}
                    defaultValue={this.dimensions.w}
                    onBlur={(v) => this.updateDimensions({w: v, h: this.dimensions.h})}/>
                <NumberInputGroup
                    id={'height'}
                    label={'Height'}
                    defaultValue={this.dimensions.h}
                    onBlur={(v) => this.updateDimensions({w: this.dimensions.w, h: v})}/>

                <ColorInputGroup
                    id={'backgroundColor'}
                    label={'Background Color'}
                    defaultValue={this.style.backgroundColor}
                    onChange={(v) => {
                        this.style.backgroundColor = v;
                        this.draw();
                    }}/>

                <NumberInputGroup
                    id={'margin-left'}
                    label={'Margin Left'}
                    defaultValue={this.style.margin.l}
                    onBlur={(v) => {
                        this.style.margin.l = v;
                        this.draw();
                    }}/>
                <NumberInputGroup
                    id={'margin-right'}
                    label={'Margin Right'}
                    defaultValue={this.style.margin.r}
                    onBlur={(v) => {
                        this.style.margin.r = v;
                        this.draw();
                    }}/>
                <NumberInputGroup
                    id={'margin-top'}
                    label={'Margin Top'}
                    defaultValue={this.style.margin.t}
                    onBlur={(v) => {
                        this.style.margin.t = v;
                        this.draw();
                    }}/>
                <NumberInputGroup
                    id={'margin-bottom'}
                    label={'Margin Bottom'}
                    defaultValue={this.style.margin.b}
                    onBlur={(v) => {
                        this.style.margin.b = v;
                        this.draw();
                    }}/>
            </InspectorGroup>
        );
    };

    DataPointsInspectorGroup = () => {
        return null;
    }

    onChange() {
        this.draw();
    }

    rerenderInspector() {
        const target = document.getElementById('inspector');
        if (target) ReactDOM.render(<this.InspectorBody/>, target);
    }

    InspectorBody = () => {
        const [selectedGroup, setSelectedGroup] = useState('worksheet');
        return (
            <div className={'Inspector-Body'}>
                <this.WorksheetInspectorGroup
                    selectedGroup={selectedGroup}
                    setSelectedGroup={setSelectedGroup}/>
                <this.DataPointsInspectorGroup/>
                {Object.entries(this.axes).map(([key, axis]) => (
                    <axis.AxisInspectorGroup
                        key={key}
                        onChange={() => this.onChange()}
                        selectedGroup={selectedGroup}
                        setSelectedGroup={setSelectedGroup}/>
                ))}
            </div>
        );
    }

    Inspector = () => {
        return (
            <div
                id={'inspector'}
                className={'Inspector --layer-1 --scrollbar-hidden'}>
                <this.InspectorBody/>
            </div>
        );
    }
}

export default Visualization;