import React from "react";
import {InspectorGroup} from "../../input/Inspector";
import {
    FACTOR_OPTIONS,
    FACTORS,
    POSITION_OPTIONS,
    POSITION_START,
} from "../../../constants/configuration";
import {AXIS_COLOR, LABEL_COLOR} from "../../../constants/colors";
import {parseColor} from "../../../helpers/color";
import {getNextLowerPower} from "../../../helpers/getNextLowerPower/getNextLowerPower";
import {updateInputValue} from "../../../helpers/updateInputValue";
import {CheckboxGroup} from "../../input/Checkbox";
import {TextInputGroup} from "../../input/TextInput";
import {NumberInputGroup} from "../../input/NumberInput";
import {RangeInputGroup} from "../../input/RangeInputGroup";
import {ColorInputGroup} from "../../input/ColorInput";
import {SelectGroup} from "../../input/Select";

class Axis {
    visualization = null;
    type = null;
    scale = null;
    
    style = {
        position: POSITION_START,

        minTick: 0,
        maxTick: 100,
        tickInterval: 10,
        tickFactor: 1,
        tickLength: 8,
        strokeColor: AXIS_COLOR,
        strokeWidth: 1,

        prefix: '',
        suffix: '',
        labelSize: 16,
        labelMargin: 4,
        labelFontFamily: 'sans-serif',
        labelFontSize: 12,
        labelTextAlign: 'center',
        labelBaseline: 'top',
        labelFillColor: LABEL_COLOR,

        title: 'x-axis',
        titleSize: 16,
        titleMargin: 8,
        titleRotate: 0,
        titleFontFamily: 'sans-serif',
        titleFontSize: 12,
        titleTextAlign: 'center',
        titleBaseline: 'middle',
        titleFillColor: LABEL_COLOR,

        showGrid: true,
        gridStrokeColor: AXIS_COLOR,
        gridStrokeWidth: 1,
        gridInterval: 10,
    };

    options = {
        isZeroBased: false,
    }

    resizing = {title: false, titleMargin: false, label: false, labelMargin: false, ticks: false};

    constructor(viz, id, name, options) {
        this.visualization = viz;
        this.id = id;
        this.name = name ?? 'axis';
        this.updateOptions(options);
    }

    updateOptions(options) {
        if (typeof options !== "object") return;
        Object.entries(options)?.forEach(([key, value]) => {
            if (this.options.hasOwnProperty(key))
                this.options[key] = value;
        });
    }

    autoUpdateStyle(variable, options = {}) {
        this.style.title = variable?.name ?? 'axis';
        this.autoUpdateTicks(options?.minValue ?? variable?.minValue, options?.maxValue ?? variable?.maxValue);
        this.updateTitleSize();
        this.updateLabelSize();
    }

    autoUpdateTicks(min, max) {
        const range = max - min;
        const interval = getNextLowerPower(range, 10);
        const factor = getNextLowerPower(interval, 1000);
        let minTick = this.getMinTick(min, interval);

        let maxTick = minTick;
        while (maxTick < max) maxTick += interval;

        this.style.minTick = minTick;
        this.style.maxTick = maxTick;
        this.style.tickInterval = interval;
        this.style.gridInterval = interval;
        this.style.tickFactor = factor;
    }

    getMinTick(min, interval) {
        if (this.options.isZeroBased) return 0;
        let minTick = getNextLowerPower(min, 10);
        if (minTick < interval) return 0;
        while (minTick + interval < min) minTick += interval;
        if (minTick === interval) return 0;
        return minTick;
    }

    getAxisPosition(bounds) {
    }

    modifyDatapointBounds(bounds) {
    }

    getBounds(marginBounds) {
    }

    getTotalSize() {
        const s = this.style;
        return s.tickLength + s.labelMargin + s.labelSize + s.titleMargin + s.titleSize;
    }

    getOffsetModifier() {
        return (this.style.position === POSITION_START ? -1 : 1);
    }

    getTitleOffset() {
        const s = this.style;
        const offset = s.tickLength + s.labelMargin + s.labelSize + s.titleMargin;
        return offset * this.getOffsetModifier();
    }

    getTitleMarginOffset() {
        const s = this.style;
        const offset = s.tickLength + s.labelMargin + s.labelSize;
        return offset * this.getOffsetModifier();
    }

    getLabelOffset() {
        const s = this.style;
        const offset = s.tickLength + s.labelMargin;
        return offset * this.getOffsetModifier();
    }

    getLabelMarginOffset() {
        const s = this.style;
        const offset = s.tickLength;
        return offset * this.getOffsetModifier();
    }

    applyTitleStyles(ctx) {
        ctx.font = `${this.style.titleFontSize}px ${this.style.titleFontFamily}`;
        ctx.fillStyle = parseColor(this.style.titleFillColor);
        ctx.textAlign = this.style.titleTextAlign;
        ctx.textBaseline = this.style.titleBaseline;
    }

    applyLabelStyles(ctx) {
        ctx.font = `${this.style.labelFontSize}px ${this.style.labelFontFamily}`;
        ctx.fillStyle = parseColor(this.style.labelFillColor);
        ctx.textAlign = this.style.labelTextAlign;
        ctx.textBaseline = this.style.labelBaseline;
    }

    applyGridStyles(ctx) {
        ctx.strokeStyle = parseColor(this.style.gridStrokeColor);
        ctx.lineWidth = this.style.gridStrokeWidth;
    }

    applyTickStyles(ctx) {
        ctx.strokeStyle = parseColor(this.style.strokeColor);
        ctx.lineWidth = this.style.strokeWidth;
    }

    forEachTick(ctx, bounds, callback) {
    }

    drawTicks(ctx, bounds) {
        this.applyTickStyles(ctx);
    }

    drawGrid(ctx, bounds) {
        this.applyGridStyles(ctx);
    }

    drawLabels(ctx, bounds) {
        this.applyLabelStyles(ctx)
    }

    getTitle() {
        let text = this.style.title;
        if (FACTORS[this.style.tickFactor]) text += ` in ${FACTORS[this.style.tickFactor]}s`
        return text;
    }

    drawTitle(ctx, bounds) {
        this.applyTitleStyles(ctx);
    }

    draw(ctx, bounds) {
        this.drawGrid(ctx, bounds);
        this.drawTicks(ctx, bounds);
        this.drawLabels(ctx, bounds);
        this.drawTitle(ctx, bounds);
    }

    startResize(pos, datapointBounds) {
        this.resizing = this.checkHover(pos, datapointBounds);
    }

    handleResize(dif, datapointBounds) {
        this.highlight(this.resizing, datapointBounds);
        const {title, titleMargin, label, labelMargin, ticks} = this.resizing;
        if (title) this.resizeTitle(dif);
        if (titleMargin) this.resizeTitleMargin(dif);
        if (label) this.resizeLabel(dif);
        if (labelMargin) this.resizeLabelMargin(dif);
        if (ticks) this.resizeTicks(dif);
    }

    resizeTitle(dif) {
        this.visualization.autoUpdateStyle();
    }

    resizeTitleMargin(dif) {
        const v = (this.style.titleMargin + dif).clamp(0, 100);
        this.style.titleMargin = v;
        updateInputValue(`${this.id}-titleMargin`, v);
        this.visualization.autoUpdateStyle();
    }

    resizeLabel(dif) {
        this.visualization.autoUpdateStyle();
    }

    resizeLabelMargin(dif) {
        const v = (this.style.labelMargin + dif).clamp(0, 100);
        this.style.labelMargin = v;
        updateInputValue(`${this.id}-labelMargin`, v);
        this.visualization.autoUpdateStyle();
    }

    resizeTicks(dif) {
        this.visualization.autoUpdateStyle();
    }

    handleHover(pos, datapointBounds) {
        const hovering = this.checkHover(pos, datapointBounds);
        this.highlight(hovering, datapointBounds);
    }

    checkHover(pos, bounds) {
        return {title: false, titleMargin: false, label: false, labelMargin: false, ticks: false};
    }

    checkHoverTitle(coordinate, baseline) {
        const edgeA = baseline + this.getTitleOffset();
        const edgeB = edgeA + (this.style.titleSize * this.getOffsetModifier());
        return (coordinate > Math.min(edgeA, edgeB) && coordinate <= Math.max(edgeA, edgeB));
    }

    checkHoverTitleMargin(coordinate, baseline) {
        const edgeA = baseline + this.getTitleMarginOffset();
        const edgeB = edgeA + (this.style.titleMargin * this.getOffsetModifier());
        return (coordinate > Math.min(edgeA, edgeB) && coordinate <= Math.max(edgeA, edgeB));
    }

    checkHoverLabel(coordinate, baseline) {
        const edgeA = baseline + this.getLabelOffset();
        const edgeB = edgeA + (this.style.labelSize * this.getOffsetModifier());
        return (coordinate > Math.min(edgeA, edgeB) && coordinate <= Math.max(edgeA, edgeB));
    }

    checkHoverLabelMargin(coordinate, baseline) {
        const edgeA = baseline + this.getLabelMarginOffset();
        const edgeB = edgeA + (this.style.labelMargin * this.getOffsetModifier());
        return (coordinate > Math.min(edgeA, edgeB) && coordinate <= Math.max(edgeA, edgeB));
    }

    checkHoverTicks(coordinate, baseline) {
        const edgeA = baseline;
        const edgeB = edgeA + (this.style.tickLength * this.getOffsetModifier());
        return (coordinate > Math.min(edgeA, edgeB) && coordinate <= Math.max(edgeA, edgeB));
    }

    highlight({title, titleMargin, label, labelMargin, ticks}, bounds) {
        const ctx = this.visualization.sgfCtx;
        if (!ctx) return;
        // if (title) this.highlightTitle(ctx, bounds);
        if (titleMargin) this.highlightTitleMargin(ctx, bounds);
        // if (label) this.highlightLabel(ctx, bounds);
        if (labelMargin) this.highlightLabelMargin(ctx, bounds);
        // if (ticks) this.highlightTicks(ctx, bounds);
    }

    highlightTitle(ctx, bounds) {
    }

    highlightTitleMargin(ctx, bounds) {
    }

    highlightLabel(ctx, bounds) {
    }

    highlightLabelMargin(ctx, bounds) {
    }

    highlightTicks(ctx, bounds) {
    }

    updatePosition(v) {
        this.style.position = v;
    }

    updateTitleSize(ctx = this.visualization.ctx) {
        if (!ctx) return;
        this.applyTitleStyles(ctx);
        const m = ctx.measureText(this.style.title);
        this.style.titleSize = m.actualBoundingBoxDescent + m.actualBoundingBoxAscent;
    }

    updateLabelSize(ctx = this.visualization.ctx) {
        if (!ctx) return;
        this.applyLabelStyles(ctx);
        const m = ctx.measureText(`${this.style.maxTick / this.style.tickFactor}`);
        this.style.labelSize = m.actualBoundingBoxDescent + m.actualBoundingBoxAscent;
    }


    AxisInspectorGroup = ({onChange, selectedGroup, setSelectedGroup}) => {
        return (
            <InspectorGroup
                id={this.id}
                title={this.name}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}>
                <SelectGroup
                    id={`${this.id}-position`}
                    label={'Position'}
                    defaultValue={this.style.position}
                    options={POSITION_OPTIONS}
                    onChange={(v) => {
                        this.updatePosition(v);
                        onChange();
                    }}/>

                <h5 className={'Inspector-SubHeadline'}>Ticks</h5>
                <RangeInputGroup
                    id={`${this.id}-tickRange`}
                    label={'Range'}
                    min={{
                        defaultValue: this.style.minTick,
                        disabled: this.options.isZeroBased,
                        onBlur: v => {
                            this.style.minTick = v;
                            onChange();
                        }
                    }}
                    max={{
                        defaultValue: this.style.maxTick,
                        onBlur: v => {
                            this.style.maxTick = v;
                            this.updateLabelSize();
                            onChange();
                        }
                    }}/>
                <NumberInputGroup
                    id={`${this.id}-tickInterval`}
                    label={'Interval'}
                    defaultValue={this.style.tickInterval}
                    onBlur={v => {
                        this.style.tickInterval = v;
                        onChange();
                    }}/>
                <NumberInputGroup
                    id={`${this.id}-tickLength`}
                    label={'Stroke Length'}
                    defaultValue={this.style.tickLength}
                    onBlur={v => {
                        this.style.tickLength = v;
                        onChange();
                    }}/>
                <NumberInputGroup
                    id={`${this.id}-strokeWidth`}
                    label={'Stroke Width'}
                    defaultValue={this.style.strokeWidth}
                    onBlur={v => {
                        this.style.strokeWidth = v;
                        onChange();
                    }}/>
                <ColorInputGroup
                    id={`${this.id}-strokeColor`}
                    label={'Stroke Color'}
                    defaultValue={this.style.strokeColor}
                    onChange={v => {
                        this.style.strokeColor = v;
                        onChange();
                    }}/>
                <SelectGroup
                    id={`${this.id}-tickFactor`}
                    label={'Factor'}
                    defaultValue={this.style.tickFactor}
                    options={FACTOR_OPTIONS}
                    onChange={(v) => {
                        this.style.tickFactor = v;
                        onChange();
                    }}/>

                <h5 className={'Inspector-SubHeadline'}>Grid</h5>
                <CheckboxGroup
                    id={`${this.id}-showGrid`}
                    label={'show'}
                    defaultValue={this.style.showGrid}
                    onChange={v => {
                        this.style.showGrid = v;
                        onChange();
                    }}/>
                <NumberInputGroup
                    id={`${this.id}-gridInterval`}
                    label={'Interval'}
                    defaultValue={this.style.gridInterval}
                    onBlur={v => {
                        this.style.gridInterval = v;
                        onChange();
                    }}/>
                <NumberInputGroup
                    id={`${this.id}-gridStrokeWidth`}
                    label={'Stroke Width'}
                    defaultValue={this.style.gridStrokeWidth}
                    onBlur={v => {
                        this.style.gridStrokeWidth = v;
                        onChange();
                    }}/>
                <ColorInputGroup
                    id={`${this.id}-gridStrokeColor`}
                    label={'Stroke Color'}
                    defaultValue={this.style.gridStrokeColor}
                    onChange={v => {
                        this.style.gridStrokeColor = v;
                        onChange();
                    }}/>

                <h5 className={'Inspector-SubHeadline'}>Title</h5>
                <TextInputGroup
                    id={`${this.id}-title`}
                    label={'Text'}
                    defaultValue={this.style.title}
                    onBlur={v => {
                        this.style.title = v;
                        onChange();
                    }}/>
                <NumberInputGroup
                    id={`${this.id}-titleFontSize`}
                    label={'Font Size'}
                    defaultValue={this.style.titleFontSize}
                    onChange={v => {
                        this.style.titleFontSize = v;
                        this.updateTitleSize();
                        onChange();
                    }}/>
                <ColorInputGroup
                    id={`${this.id}-titleFillColor`}
                    label={'Color'}
                    defaultValue={this.style.titleFillColor}
                    onChange={v => {
                        this.style.titleFillColor = v;
                        onChange();
                    }}/>
                <NumberInputGroup
                    id={`${this.id}-titleMargin`}
                    label={'Margin'}
                    defaultValue={this.style.titleMargin}
                    onChange={v => {
                        this.style.titleMargin = v;
                        onChange();
                    }}/>

                <h5 className={'Inspector-SubHeadline'}>Labels</h5>
                <TextInputGroup
                    id={`${this.id}-prefix`}
                    label={'Prefix'}
                    defaultValue={this.style.prefix}
                    onBlur={v => {
                        this.style.prefix = v;
                        onChange();
                    }}/>
                <TextInputGroup
                    id={`${this.id}-suffix`}
                    label={'Suffix'}
                    defaultValue={this.style.suffix}
                    onBlur={v => {
                        this.style.suffix = v;
                        onChange();
                    }}/>
                <NumberInputGroup
                    id={`${this.id}-labelFontSize`}
                    label={'Font Size'}
                    defaultValue={this.style.labelFontSize}
                    onChange={v => {
                        this.style.labelFontSize = v;
                        this.updateLabelSize();
                        onChange();
                    }}/>
                <ColorInputGroup
                    id={`${this.id}-labelFillColor`}
                    label={'Color'}
                    defaultValue={this.style.labelFillColor}
                    onChange={v => {
                        this.style.labelFillColor = v;
                        onChange();
                    }}/>
                <NumberInputGroup
                    id={`${this.id}-labelMargin`}
                    label={'Margin'}
                    defaultValue={this.style.labelMargin}
                    onChange={v => {
                        this.style.labelMargin = v;
                        onChange();
                    }}/>
            </InspectorGroup>
        );
    };
}

export default Axis;