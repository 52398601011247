import Visualization from "../core/Visualization";
import Attribute from "../attributes/Attribute";
import {ORIENTATION_OPTIONS, SCALE_CATEGORICAL, SCALE_QUANTITATIVE} from "../../../constants/configuration";
import {InspectorGroup} from "../../input/Inspector";
import {NumberInputGroup, SelectGroup} from "../../input";
import React from "react";

class UnitChart extends Visualization {
    constructor() {
        super();
        this.attributes.label = new Attribute(this, 'label', {
            isRequired: true,
            scale: [SCALE_CATEGORICAL]
        });
        this.attributes.unit = new Attribute(this, 'unit', {
            isRequired: true,
            scale: [SCALE_QUANTITATIVE]
        })
    }

    drawDataPoints(ctx, bounds) {
        this.applyDataPointStyles(ctx);
    }

    updateOrientation(orientation) {
        if (orientation === this.style.orientation) return;
        this.style.orientation = orientation;
        this.autoUpdateStyle();
        this.rerenderInspector();
    }

    DataPointsInspectorGroup = () => {
        return (
            <InspectorGroup
                id={`${this.id}`}
                title={'Data Points'}>
                <SelectGroup
                    id={`${this.id}-orientation`}
                    label={'Orientation'}
                    options={ORIENTATION_OPTIONS}
                    defaultValue={this.style.orientation}
                    onChange={v => {
                        // this.style.orientation = v;
                        this.updateOrientation(v);
                        this.onChange();
                    }}/>
                <NumberInputGroup
                    id={`${this.id}-unitCols`}
                    label={'Columns'}
                    defaultValue={this.style.unitCols}
                    onChange={v => {
                        this.style.unitCols = v;
                        this.onChange();
                    }}/>
                <NumberInputGroup
                    id={`${this.id}-unitRows`}
                    label={'Rows'}
                    defaultValue={this.style.unitRows}
                    onChange={v => {
                        this.style.unitRows = v;
                        this.onChange();
                    }}/>
            </InspectorGroup>
        );
    }
}

export default UnitChart;