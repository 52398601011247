import React, {useEffect, useLayoutEffect, useState} from "react";
import {wrapGrid} from "animate-css-grid";

const useAnimatedGrid = (ref) => {
    const [itemCount, setItemCount] = useState(3);
    const [imgWidth, setImgWidth] = useState(180);

    // animate grid with animate-css-grid
    useEffect(() => {
        const el = ref.current;
        if (!el) return;
        const {unwrapGrid} = wrapGrid(el, {
            easing: 'easeInOut', stagger: 6, duration: 300
        });
        return () => unwrapGrid();
    }, []);

    // adjust grid image size whenever the screen size changes.
    // because the item size changes when opened, width:100% does not work.
    useLayoutEffect(() => {
        const el = ref.current;
        if (!el) return;
        const ro = new ResizeObserver((entries) => {
            const gridWidth = entries[0].target.getBoundingClientRect().width;
            const itemCount = Math.floor((gridWidth + 24) / (200 + 24));
            const itemWidth = (gridWidth - ((itemCount - 1) * 24)) / itemCount;
            // limits the span "grid-column: auto / span 3" to the item count of the grid
            setItemCount(itemCount);
            setImgWidth(itemWidth - 32);
        });
        ro.observe(el);
        return () => ro.disconnect();
    }, []);

    return {itemCount, imgWidth};
};

export default useAnimatedGrid;