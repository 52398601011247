import React, {createContext, useContext, useState} from "react";
import Papa from "papaparse";
import {categorizeVariables} from "../helpers/categorizeVariables";

const DataContext = createContext(null);

export const DataContextProvider = ({children}) => {
    const [file, setFile] = useState({
        name: '',
        csv: '',
        selectedDelimiter: '',
        selectedLinebreak: '',
        detectedDelimiter: '',
        detectedLinebreak: '',
        errors: [],
    });
    const [records, setRecords] = useState([]);
    const [variables, setVariables] = useState([]);

    function selectDelimiter(v) {
        setFile(prev => ({...prev, selectedDelimiter: v}));
        if (file.csv) parseCSVString(file.csv, {delimiter: v});
    }

    function selectLinebreak(v) {
        setFile(prev => ({...prev, selectedLinebreak: v}));
        if (file.csv) parseCSVString(file.csv, {linebreak: v});
    }

    function removeFile() {
        setFile(prev => ({...prev, name: '', csv: '', detectedDelimiter: '', detectedLinebreak: ''}));
        setRecords([]);
        setVariables([]);
    }

    function readFile(file) {
        const reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onerror = (e) => console.error('error reading file:', e);
        reader.onload = (e) => {
            setFile(prev => ({
                ...prev, name: file.name, csv: e.target.result, detectedDelimiter: '', detectedLinebreak: ''
            }));
            parseCSVString(e.target.result);
        }
    }

    function parseCSVString(csv, options) {
        const croppedCSV = csv.replace(/(?:\r\n|\n\r|\r|\n)*$/, '') // removes last linebreak
        const result = Papa?.parse(croppedCSV, {
            delimiter: options?.delimiter ?? file.selectedDelimiter,
            newLine: options?.linebreak ?? file.selectedLinebreak,
            header: true,
            dynamicTyping: true
        });
        setFile(prev => ({
            ...prev,
            errors: result.errors,
            detectedDelimiter: result.meta.delimiter,
            detectedLinebreak: result.meta.linebreak,
        }));
        setRecords(result.data);
        const variables = Object.keys(result.data[0]);
        setVariables(categorizeVariables(variables, result.data));
    }

    function toggleVariable(v, value) {
        setVariables(prev => prev.map(v2 => {
            if (v2.name === v.name) v2.selected = value;
            return v2;
        }))
    }

    return <DataContext.Provider value={{
        file,
        records,
        variables,
        removeFile,
        readFile,
        selectDelimiter,
        selectLinebreak,
        parseCSVString,
        toggleVariable
    }}>
        {children}
    </DataContext.Provider>;
};

export const useDataHandler = () => {
    return useContext(DataContext);
};