import {createContext, useContext, useState} from "react";
import {useDataHandler} from "./DataContext";
import {VISUAL_RELATIONSHIPS} from "../constants/data";
import allVisualizations from '../assets/visualization_types.json';
import useVariableFilter from "../helpers/useVariableFilter";
import useSettingsFilter from "../helpers/useSettingsFilter";
import useVizGroups from "../helpers/useVizGroups";

const RecommendationContext = createContext(null)

export const RecommendationContextProvider = ({children}) => {
    const [relationships, setRelationships] = useState(VISUAL_RELATIONSHIPS);
    const [selected, setSelected] = useState(null);

    const dataHandler = useDataHandler();
    const filteredByVariables = useVariableFilter(allVisualizations, dataHandler.variables);
    const filteredBySettings = useSettingsFilter(filteredByVariables, relationships);
    const groupedVisualizations = useVizGroups(filteredBySettings);

    function toggleRelationship(r, value) {
        setRelationships(prev => (
            prev.map(r2 => {
                if (r2.name === r.name) r2.selected = value;
                return r2;
            })
        ))
    }

    function select(id) {
        setSelected(prev => prev === id ? null : id);
    }

    function reset() {
        setSelected([]);
    }

    return (
        <RecommendationContext.Provider
            value={{
                groups: groupedVisualizations,
                selected,
                select,
                reset,
                relationships,
                toggleRelationship
            }}>
            {children}
        </RecommendationContext.Provider>
    );
};

export const useRecommendations = () => {
    return useContext(RecommendationContext);
};