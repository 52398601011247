import Visualization from "../core/Visualization";
import {InspectorGroup} from "../../input/Inspector";
import XAxis from "../axes/XAxis";
import YAxis from "../axes/YAxis";
import Attribute from "../attributes/Attribute";
import {SCALE_CATEGORICAL, SCALE_QUANTITATIVE} from "../../../constants/configuration";
import {ColorInputGroup} from "../../input/ColorInput";


class Histogram extends Visualization {

    constructor() {
        super();
        this.axes.x = new XAxis(this);
        this.axes.y = new YAxis(this, null, null, {isZeroBased: true});
        this.axes.y.style.title = 'count';
        this.attributes.x = new Attribute(this, 'x', {
            isRequired: true,
            scale: [SCALE_QUANTITATIVE]
        })
    }

    autoUpdateAxisStyle(records) {
        super.autoUpdateAxisStyle(records);
        this.axes.x.autoUpdateStyle(this.attributes.x.variables[0]);
        const maxBin = Math.max(...this.getBins());
        this.axes.y.autoUpdateTicks(0, maxBin);
    }

    getBins() {
        const {minTick, maxTick, tickInterval} = this.axes.x.style;
        const values = this.records.map(r => r[this.attributes.x.variables[0]?.name]);
        const bins = [];
        for (let i = minTick; i < maxTick; i += tickInterval) {
            bins.push(values.filter(v => v > i && v <= i + tickInterval).length);
        }
        return bins;
    }

    drawDataPoints(ctx, bounds) {
        this.applyDataPointStyles(ctx);
        const bins = this.getBins();
        const w = bounds.w / bins.length;
        bins.forEach((bin, i) => {
            const {minTick, maxTick} = this.axes.y.style;
            const h = bin.map(minTick, maxTick, 0, bounds.h);
            const x = bounds.l + (i * w);
            const y = bounds.b - h;
            ctx.fillRect(x, y, w, h);
        });
    }

    DataPointsInspectorGroup = () => {
        const id = 'datapoints';
        return (
            <InspectorGroup
                id={`${id}`}
                title={'Data Points'}>
                <ColorInputGroup
                    id={`${id}-fillColors`}
                    label={'Color'}
                    defaultValue={this.style.fillColors[0]}
                    onChange={v => {
                        this.style.fillColors[0] = v;
                        this.onChange();
                    }}
                />
            </InspectorGroup>
        );
    }
}

export default Histogram;