import {Link, useLocation} from "react-router-dom";
import React from "react";
import {GearIcon} from "./iconography/GearIcon";
import {BookIcon} from "./iconography/BookIcon";

const Header = () => {
    return <div className={'Editor-Header'}>
        <Logo/>
        <Nav/>
        {/*<Actions/>*/}
    </div>;
};

const Actions = () => {
    return <div className={'Actions'}>
        <Link to={'https://docs.visually-app.com'} className={'Action'}>
            <BookIcon/>
        </Link>
        <button className={'Action'}>
            <GearIcon/>
        </button>
    </div>
};

const Logo = () => {
    return <div className={'Logo'}>
        <a
            className={'Logo-Link'} href={'https://visually-app.com'}
            target={'_blank'} rel={'noreferrer'}>
            Visually <span className={'Version'}>(alpha)</span>
        </a>
    </div>;
}

const Nav = () => {
    return <nav className={'Nav'}>
        <ul className={'Nav-Ul'}>
            <NavLink path={'/data'} label={'Data'}/>
            <NavLink path={'/visualize'} label={'Visualize'}/>
            <NavLink path={'/attributes'} label={'Attributes'}/>
            <NavLink path={'/customize'} label={'Customize'}/>
        </ul>
    </nav>
};

const NavLink = (props) => {
    const {path, label} = props
    const location = useLocation();
    const selected = location.pathname === path;
    return <li className={'Nav-Li' + (selected ? ' Nav-Li--selected' : '')}>
        <Link className={'Nav-Link' + (selected ? ' Nav-Link--selected' : '')} to={path}>
            {label}
        </Link>
    </li>;
};

export default Header;