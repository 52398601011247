
// todo: integrate decimal and negative numbers
export function getNextLowerPower(number, factor) {
    const sign = Math.sign(number);
    const absNumber = Math.abs(number);
    const exponent = Math.floor(Math.log(absNumber) / Math.log(factor));
    const result = factor ** exponent;
    return result * sign;
}

export function getNextHigherAxisNumber(number) {
    const factor = getNextLowerPower(number, 10);
    const normalizedNumber = number / factor;
    if (normalizedNumber < 2) return factor * 2;
    if (normalizedNumber < 5) return factor * 5;
    return factor * 10;
}

export function getNextLowerAxisNumber(number) {
    const factor = getNextLowerPower(number, 10);
    const normalizedNumber = number / factor;
    if (normalizedNumber > 5) return factor * 5;
    if (normalizedNumber > 2) return factor * 2;
    if (normalizedNumber > 1) return factor;
    return factor / 2;
}
