import {Link, useOutletContext} from "react-router-dom";
import {useDrag, useDrop} from "react-dnd";
import React, {useEffect, useState} from "react";
import {useVisualization} from "../../context/VisualizationContext";
import Worksheet from "../visualizations/Worksheet";
import {useDataHandler} from "../../context/DataContext";
import DraggableVariable from "../DraggableVariable";
import DroppableAttribute from "../DroppableAttribute";
import {InspectorGroup} from "../input/Inspector";
import {Button} from "../Button";


const AttributesPanel = () => {
    const {visualization} = useVisualization();
    const {variables: _variables} = useDataHandler();

    const [variables, setVariables] = useState(_variables ?? []);
    const [attributes, setAttributes] = useState(visualization.getAttributesAsArray() ?? []);

    useEffect(() => {
        const _attributes = visualization.getAttributesAsArray();
        setAttributes(_attributes);
    }, [visualization]);

    useEffect(() => {
        setVariables(_variables);
    }, [_variables]);

    function getVariableAssignment(variable) {
        return attributes.find(a => a.variables.some(v => v.name === variable.name));
    }

    function onDrop(variable, attributeKey) {
        const otherAttribute = getVariableAssignment(variable);
        const otherVariable = visualization.attributes[attributeKey]?.variables[0];
        visualization.assignVariableToAttribute(variable, attributeKey);
        visualization.assignVariableToAttribute(otherVariable, otherAttribute?.name);
        setAttributes(visualization.getAttributesAsArray());
    }

    return (
        <>
            <div className={'Pane Pane--inspector'}>
                <div className={'Inspector --layer-1'}>
                    <div className={'Inspector-Body'}>

                        <InspectorGroup title={'Variables'}>
                            {_variables?.length > 0
                                ? (
                                    <div className={'DragAndDrop'}>
                                        {variables?.filter(v => !getVariableAssignment(v)).map((variable) => (
                                            <DraggableVariable
                                                key={variable.name}
                                                label={variable.name}
                                                variable={variable}
                                                onDrop={onDrop}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <Link className={'Caption'} to={'/data'}>
                                        Upload a file to see variables.
                                    </Link>
                                )}
                        </InspectorGroup>

                        <InspectorGroup title={'Attributes'}>
                            <div className={'DragAndDrop'}>
                                {visualization && attributes?.map((attribute) => (
                                    <DroppableAttribute
                                        key={attribute.name}
                                        label={attribute.name}
                                        attribute={attribute}>
                                        {attribute.variables.map((variable) => (
                                            <DraggableVariable
                                                key={variable.name}
                                                label={variable.name}
                                                variable={variable}
                                                onDrop={onDrop}/>
                                        ))}
                                    </DroppableAttribute>
                                ))}
                            </div>
                        </InspectorGroup>

                    </div>
                </div>
                <Link
                    className={'Button Button--theme-primary Button--span'}
                    to={'/customize'}>
                    Customize
                </Link>
            </div>

            <div className={'Pane --padding-tb Pane--grow --layer-0'}>
                <div className={'Workspace '}>
                    <Worksheet/>
                </div>
            </div>
        </>
    );
};

export default AttributesPanel;
