import {getGaps, getSmallestGap} from "../../../helpers/dataCategorization";
import Plot from "../core/Plot";

class LineChart extends Plot {

    constructor() {
        super();
    }

    getGraphSegments(bounds) {
        const values = this.records.map(r => r[this.attributes.x.variables[0]?.name]);
        const minGap = getSmallestGap(values);
        let segments = [];
        let segment = [];
        this.records?.sort((a, b) => a - b).forEach((record) => {
            const x = record[this.attributes.x.variables[0]?.name];
            const y = record[this.attributes.y.variables[0]?.name];
            const gap = x - segment[segment.length - 1]?.x;
            if (gap !== minGap && segment?.length > 0) {
                segments.push(segment);
                segment = [];
            }
            segment.push({x, y});
        });
        segments.push(segment);
        return segments.map(segment =>
            segment.map(({x, y}) => ({
                x: x?.map(this.axes.x.style.minTick, this.axes.x.style.maxTick, bounds.l, bounds.r),
                y: y?.map(this.axes.y.style.minTick, this.axes.y.style.maxTick, bounds.b, bounds.t)
            }))
        );
    }

    forEachGraphSegment(callback, bounds) {
        const segments = this.getGraphSegments(bounds);
        segments.forEach((segment, index) => {
            callback(segment, index);
        });
    }

    drawGraph(ctx, bounds) {
        ctx.beginPath();
        this.forEachGraphSegment((segment) => {
            segment.forEach(({x, y}, idx) => {
                if (idx === 0) ctx.moveTo(x, y);
                ctx.lineTo(x, y);
            });
        }, bounds);
        ctx.stroke();
    }

    drawArea(ctx, bounds) {
        ctx.beginPath();
        this.forEachGraphSegment((segment) => {
            ctx.moveTo(segment[0].x, bounds.b);
            segment.forEach(({x, y}, idx) => {
                ctx.lineTo(x, y);
            });
            ctx.lineTo(segment[segment.length - 1].x, bounds.b);
            ctx.closePath();
            ctx.fill();
        }, bounds);
    }

    drawDataPoints(ctx, bounds) {
        this.applyDataPointStyles(ctx);
        this.drawArea(ctx, bounds);
        this.drawGraph(ctx, bounds);
    }

    DataPointsInspectorGroup = () => {

    }
}

export default LineChart;