import React from "react";
import {InspectorGroup} from "../../input/Inspector";
import {
    LAYOUT_OPTIONS, LAYOUT_STACKED,
    ORIENTATION_OPTIONS, ORIENTATION_VERTICAL,
    SCALE_CATEGORICAL,
    SCALE_QUANTITATIVE,
    SHAPE_OPTIONS
} from "../../../constants/configuration";
import XAxis from "../axes/XAxis";
import YAxis from "../axes/YAxis";
import Attribute from "../attributes/Attribute";
import CategoricalXAxis from "../axes/CategoricalXAxis";
import CategoricalYAxis from "../axes/CategoricalYAxis";
import BoxPlot from "../core/BoxPlot";
import {NumberInputGroup} from "../../input/NumberInput";
import {ColorInputGroup} from "../../input/ColorInput";
import {SelectGroup} from "../../input/Select";

class BarChart extends BoxPlot {
    constructor() {
        super();
        this.attributes.length = new Attribute(this, 'length', {
            isRequired: true,
            scale: [SCALE_QUANTITATIVE]
        });
        this.axes.y = new YAxis(this, 'y', 'y-axis', {isZeroBased: true});
    }

    autoUpdateAxisStyle(records) {
        super.autoUpdateAxisStyle(records);
        const quantitativeAxis = this.style.orientation === ORIENTATION_VERTICAL
            ? this.axes.y : this.axes.x;
        let maxValue;
        if (this.style.boxLayout === LAYOUT_STACKED) {
            let values = [];
            const lengthVariable = this.attributes.length.variables[0];
            const axesBounds = this.getDatapointBounds();
            this.forEachGroup(({records}) => {
                values.push(records.reduce((sum, r) => sum + r[lengthVariable.name], 0));
            }, axesBounds);
            maxValue = Math.max(...values);
        } else {
            maxValue = this.attributes.length.variables[0]?.maxValue;
        }
        quantitativeAxis.autoUpdateStyle(this.attributes.length.variables[0], {maxValue});
    }

    drawDataPoints(ctx, bounds) {
        const {boxLayout} = this.style;
        this.applyDataPointStyles(ctx);
        const lengthVariable = this.attributes.length.variables[0];
        this.forEachSegment(({x, y, w, h, records}, groupIdx, segmentIdx) => {
            this.applyDataPointStyles(ctx, segmentIdx);
            const value = records.reduce((sum, r) => sum + r[lengthVariable.name], 0);
            if (this.style.orientation === ORIENTATION_VERTICAL) {
                const length = value?.map(this.axes.y.style.minTick, this.axes.y.style.maxTick, 0, h);
                ctx.fillRect(x, bounds.b - length, w, length);
                if (boxLayout === LAYOUT_STACKED) ctx.translate(0, -length);
            } else {
                const length = value?.map(this.axes.x.style.minTick, this.axes.x.style.maxTick, 0, w);
                ctx.fillRect(x, y, length, h);
                if (boxLayout === LAYOUT_STACKED) ctx.translate(length, 0);
            }
        }, bounds, {
            beforeEachGroup: () => ctx.save(),
            afterEachGroup: () => ctx.restore()
        });
    }

    updateOrientation(orientation) {
        if (orientation === this.style.orientation) return;
        if (orientation === ORIENTATION_VERTICAL) {
            this.axes.x = new CategoricalXAxis(this);
            this.axes.y = new YAxis(this, 'y', 'y-axis', {isZeroBased: true});
        } else {
            this.axes.x = new XAxis(this, 'x', 'x-axis', {isZeroBased: true});
            this.axes.y = new CategoricalYAxis(this);
        }
        this.style.orientation = orientation;
        this.autoUpdateStyle();
        this.rerenderInspector();
    }

    DataPointsInspectorGroup = () => {
        return (
            <InspectorGroup
                id={`${this.id}`}
                title={'Data Points'}>
                <SelectGroup
                    id={`${this.id}-orientation`}
                    label={'Orientation'}
                    options={ORIENTATION_OPTIONS}
                    defaultValue={this.style.orientation}
                    onChange={v => {
                        // this.style.orientation = v;
                        this.updateOrientation(v);
                        this.onChange();
                    }}/>
                <SelectGroup
                    id={`${this.id}-boxLayout`}
                    label={'Layout'}
                    options={LAYOUT_OPTIONS}
                    defaultValue={this.style.boxLayout}
                    onChange={v => {
                        this.style.boxLayout = v;
                        this.autoUpdateStyle();
                        this.onChange();
                    }}/>
                <ColorInputGroup
                    id={`${this.id}-fillColors`}
                    label={'Color'}
                    defaultValue={this.style.fillColors[0]}
                    onBlur={v => {
                        this.style.fillColors[0] = v;
                        this.onChange();
                    }}/>
            </InspectorGroup>
        );
    }
}

export default BarChart