import {AXIS_COLOR, DARK_COLOR, DEFAULT_COLORS, LABEL_COLOR, LIGHT_COLOR} from "./colors";

// settings
export const SCALE_HIDDEN = null;
export const SCALE_QUANTITATIVE = 'quantitative';
export const SCALE_CATEGORICAL = 'categorical';

export const POSITION_START = 'start';
export const POSITION_END = 'end';
export const POSITION_OPTIONS = [
    {name: 'start', value: POSITION_START},
    {name: 'end', value: POSITION_END},
];
export const POSITION_X_OPTIONS = [
    {name: 'top', value: POSITION_START},
    {name: 'bottom', value: POSITION_END},
];
export const POSITION_Y_OPTIONS = [
    {name: 'left', value: POSITION_START},
    {name: 'right', value: POSITION_END},
];
export const FACTORS = {
    1000: 'thousand',
    1000000: 'million',
    1000000000: 'billion',
    1000000000000: 'trillion'
}
export const FACTOR_OPTIONS = [
    {name: 'none', value: 1},
    {name: 'in thousands', value: 1000},
    {name: 'in millions', value: 1000000},
    {name: 'in billions', value: 1000000000},
    {name: 'in trillions', value: 1000000000000}
];

// bar charts
export const LAYOUT_STACKED = 'stacked';
export const LAYOUT_GROUPED = 'grouped';
export const LAYOUT_OPTIONS = [
    {name: 'stacked', value: LAYOUT_STACKED},
    {name: 'grouped', value: LAYOUT_GROUPED},
];

export const ORIENTATION_VERTICAL = 'vertical';
export const ORIENTATION_HORIZONTAL = 'horizontal';
export const ORIENTATION_OPTIONS = [
    {name: 'vertical', value: ORIENTATION_VERTICAL},
    {name: 'horizontal', value: ORIENTATION_HORIZONTAL},
];

// todo: alphabetical, original
export const SORTING_ORIGINAL = 'original';
export const SORTING_ASCENDING = 'ascending';
export const SORTING_DESCENDING = 'descending';
export const SORTING_OPTIONS = [
    {name: 'descending', value: SORTING_DESCENDING},
    {name: 'ascending', value: SORTING_ASCENDING},
];

export const SHAPE_CIRCLE = 'circle';
export const SHAPE_PLUS = 'plus';
export const SHAPE_CROSS = 'cross';
export const SHAPE_SQUARE = 'square';
export const SHAPE_TRIANGLE = 'triangle';
export const SHAPE_OPTIONS = [
    {name: 'dot', value: SHAPE_CIRCLE},
    {name: 'plus', value: SHAPE_PLUS},
    {name: 'cross', value: SHAPE_CROSS},
    {name: 'square', value: SHAPE_SQUARE},
    {name: 'triangle', value: SHAPE_TRIANGLE},
];

export const TREEMAP_SQUARIFIED = 'squarified';
export const TREEMAP_SLICED = 'slice-and-dice';
export const TREEMAP_OPTIONS = [
    {name: 'squarified', value: TREEMAP_SQUARIFIED},
    {name: 'slice-and-dice', value: TREEMAP_SLICED},
];


// config
export const QUANTITATIVE_AXIS_CONFIG = {
    scale: SCALE_QUANTITATIVE,
    position: POSITION_START,
    // ticks
    minTick: 0,
    maxTick: 100,
    tickInterval: 10,
    tickFactor: 1,
    tickLength: 8,
    strokeColor: AXIS_COLOR,
    strokeWidth: 1,
    // labels
    prefix: '',
    suffix: '',
    labelSize: 16,
    labelMargin: 4,
    labelFontFamily: 'sans-serif',
    labelFontSize: 12,
    labelTextAlign: 'center',
    labelBaseline: 'top',
    labelFillColor: LABEL_COLOR,
    // title
    title: 'x-axis',
    titleSize: 16,
    titleMargin: 8,
    titleRotate: 0,
    titleFontFamily: 'sans-serif',
    titleFontSize: 12,
    titleTextAlign: 'center',
    titleBaseline: 'middle',
    titleFillColor: LABEL_COLOR,
    // grid
    showGrid: false,
    gridStrokeColor: AXIS_COLOR,
    gridStrokeWidth: 1,
    gridInterval: 10,
};
export const CATEGORICAL_AXIS_CONFIG = {
    ...QUANTITATIVE_AXIS_CONFIG,
    scale: SCALE_CATEGORICAL,
    title: '',
    titleSize: 0,
    titleMargin: 0,
    tickLength: 0,
    outerLabelMargin: 0,
    innerLabelMargin: 0,
};
export const HIDDEN_AXIS_CONFIG = {
    ...QUANTITATIVE_AXIS_CONFIG,
    scale: SCALE_HIDDEN
};

export const X_AXIS_CONFIG = {
    position: POSITION_END,
    labelTextAlign: 'center',
    labelBaseline: 'top',
};
export const Y_AXIS_CONFIG = {
    position: POSITION_START,
    labelTextAlign: 'right',
    labelBaseline: 'middle',
    titleRotate: -90,
};

export const DEFAULT_CONFIG = {
    dimensions: {w: 960, h: 600},
    backgroundColor: {opacity: 0, hex: '#ffffff', rgb: [255, 255, 255], hsv: [360, 0, 100]},
    margin: {t: 20, r: 20, b: 20, l: 20},
    xAxis: {...QUANTITATIVE_AXIS_CONFIG, ...X_AXIS_CONFIG},
    yAxis: {...QUANTITATIVE_AXIS_CONFIG, ...Y_AXIS_CONFIG},
    dataPoints: {
        fillColors: DEFAULT_COLORS,
        neutralColor: AXIS_COLOR,
        fillOpacity: 70,
        strokeOpacity: 100,
        strokeWidth: 1,
        // labels
        labelFillColor: LIGHT_COLOR,
        labelStrokeColor: DARK_COLOR,
        labelStrokeWidth: 0,
        labelFontFamily: 'sans-serif',
        labelFontSize: 16,
        labelTextAlign: 'center',
        labelBaseline: 'middle',
        // dot
        shape: SHAPE_CIRCLE,
        minRadius: 0,
        maxRadius: 3,
        maxValue: 1,
        // bars or boxes
        boxWidth: 8,
        boxMargin: 0, // todo: rename 'segmentMargin'
        boxMarginFactor: 0,
        groupMargin: 8,
        groupMarginFactor: 1,
        axesMargin: 8,
        layout: LAYOUT_GROUPED,
        orientation: ORIENTATION_VERTICAL,
        sorting: SORTING_DESCENDING,
        // box plot
        showStrip: false,
        showViolin: true,
        showBoxAndWhisker: true,
        showOutliers: true,
        violinStrokeWidth: 0,
        violinFillColor: {...DEFAULT_COLORS[0], opacity: 30},
        violinStrokeColor: DEFAULT_COLORS[0],
        violinWidth: 50,
        whiskerLength: 8,
        boxFillColor: {...DEFAULT_COLORS[0], opacity: 30},
        boxStrokeColor: DARK_COLOR,
        boxStrokeWidth: 1,
        dotFillColor: DEFAULT_COLORS[0],
        dotStrokeColor: DEFAULT_COLORS[0],
        dotStrokeWidth: 0,
        outlierThreshold: 1.5,
        // pie charts
        outerRadius: 100,
        innerRadius: 50,
        angleMargin: 0,
        // unit chart
        unitColumns: 3,
        // treemap
        treemapLayout: TREEMAP_SQUARIFIED
    }
};

export const INTERACTION_COLOR = 'rgba(72, 86, 181, .2)';
