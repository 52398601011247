
class Attribute {
    visualization = null;
    name = '';
    variables = [];
    maxVariableCount = 1;
    isRequired = false;
    scale = [];

    constructor(visualization, name, config) {
        this.visualization = visualization;
        this.name = name;
        Object.entries(config).forEach(([key, value]) => {
            this[key] = value;
        });
    }

    matchesVariable(variable) {
        if (!this.scale?.includes(variable.scale)) return false;
        return true;
    }

    assignVariable(variable) {

    }

    unassignVariable(variable) {

    }
}

export default Attribute;