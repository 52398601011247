import React, {useState} from "react";
import {CheckIcon} from "../iconography";

export const Checkbox = (props) => {
    const {
        id,
        value,
        defaultValue,
        highlight = false,
        disabled = false,
        className = "",
        onChange = (v) => null
    } = props;
    // to style <input type="checkbox"/> but preserve functionality
    // the input-element is hidden and replaced with a styled label
    return <>
        <label
            className={`Input Input--bool ${className}`
                + (value ? ' Input--bool--selected' : '')
                + (disabled ? ' Input--disabled' : '')}
            htmlFor={id}>
            {(value || highlight) && <CheckIcon
                className={'Input-Checkmark'
                    + (!value && highlight ? ' Input-Checkmark--active' : '')}
            />}
        </label>
        <input
            id={id}
            className={'--display-none'}
            type={'checkbox'}
            checked={value}
            disabled={disabled}
            onChange={(e) => onChange(e.target.checked)}
        />
    </>;
};

export const CheckboxGroup = (props) => {
    const {id, label, value, defaultValue, disabled = false, highlight = false, onChange = (v) => null} = props;
    const [checked, setChecked] = useState(defaultValue);
    const wrapOnChange = (v) => {
        setChecked(v);
        onChange(v);
    }

    return <div className={'InputGroup'}>
        <Checkbox
            {...props}
            value={value ?? checked}
            onChange={wrapOnChange}/>
        <label
            className={'Label Label--margin-left'
                + (disabled ? ' Label--disabled' : '')
                + (highlight ? ' Label--highlight' : '')
                + (value ?? checked ? ' Label--active' : '')}
            htmlFor={id}
        >{label}</label>
    </div>;
};