import React from "react";
import Visualization from "../core/Visualization";
import {parseColor} from "../../../helpers/color";
import {degreesToRadians} from "../../../helpers/degreeToRadians";
import {InspectorGroup} from "../../input/Inspector";
import {calculateDistance} from "../../../helpers/calculateDiagonalDistance";
import {updateInputValue} from "../../../helpers/updateInputValue";
import Attribute from "../attributes/Attribute";
import {SCALE_QUANTITATIVE} from "../../../constants/configuration";
import {NumberInputGroup} from "../../input/NumberInput";
import {ColorInputGroup} from "../../input/ColorInput";

class PieChart extends Visualization {

    constructor() {
        super();
        this.resizing.dataPoints = {inner: false, outer: false};
        this.attributes.angle = new Attribute(this, 'angle', {
            isRequired: true,
            scale: [SCALE_QUANTITATIVE]
        });
        this.attributes.label = new Attribute(this, 'label', {
            isRequired: false,
            scale: [SCALE_QUANTITATIVE]
        });
    }

    validateDrawing() {
        if (this.attributes.angle.variables.length === 0) return false;
        if (this.attributes.label.variables.length === 0) return false;
        return true;
    }

    getCenter(bounds) {
        return {x: bounds.l + bounds.w * .5, y: bounds.t + bounds.h * .5};
    }

    translateToCenter(ctx, bounds, callback) {
        const c = this.getCenter(bounds);
        ctx.save();
        ctx.translate(c.x, c.y);
        callback();
        ctx.restore();
    }

    drawDataPoints(ctx, bounds) {
        this.applyDataPointStyles(ctx);
        const angleSum = 360 - (this.style.angleMargin * this.records.length);
        const valueSum = this.records.reduce((acc, r) => acc + r[this.attributes.angle.variables[0]?.name], 0);
        const angleUnit = angleSum / valueSum;
        const {innerRadius: ri, outerRadius: ro} = this.style;
        this.translateToCenter(ctx, bounds, () => {
            let startAngle = degreesToRadians(-90 + this.style.angleMargin * .5);
            this.records.forEach((record, i) => {
                const angle = record[this.attributes.angle.variables[0]?.name] * degreesToRadians(angleUnit);
                const endPos = {x: Math.cos(startAngle + angle), y: Math.sin(startAngle + angle)};
                ctx.beginPath();
                ctx.arc(0, 0, ro, startAngle, startAngle + angle);
                ctx.lineTo(endPos.x * ri, endPos.y * ri);
                ctx.arc(0, 0, ri, startAngle + angle, startAngle, true);
                ctx.closePath();
                ctx.fillStyle = parseColor(this.style.fillColors[i]);
                ctx.fill();

                const textRadius = ri + (ro - ri) * .5;
                const textPos = {
                    x: Math.cos(startAngle + angle * .5) * textRadius,
                    y: Math.sin(startAngle + angle * .5) * textRadius};
                const text = record[this.attributes.label.variables[0]?.name];
                this.applyLabelStyles(ctx);
                if (this.style.labelStrokeWidth !== 0)
                    ctx.strokeText(text, textPos.x, textPos.y);
                ctx.fillText(text, textPos.x, textPos.y);

                startAngle += angle + degreesToRadians(this.style.angleMargin);
            });
        });
    }

    handleDataPointResize(dif, pos, bounds) {
        const distance = calculateDistance(pos, this.getCenter(bounds));
        const {inner, outer} = this.resizing.dataPoints
        if (inner)
            this.resizeInnerRadius(distance);
        if (outer)
            this.resizeOuterRadius(distance);
        this.highlightDataPoints({inner, outer}, bounds);
    }

    resizeInnerRadius(r) {
        const v = Math.max(Math.round(r), 0);
        this.style.innerRadius = v;
        if (v >= this.style.outerRadius) this.resizeOuterRadius(v + 1);
        updateInputValue('datapoints-innerRadius', v);
    }

    resizeOuterRadius(r) {
        const v = Math.max(Math.round(r), 0);
        this.style.outerRadius = v;
        if (v <= this.style.innerRadius) this.resizeInnerRadius(v - 1);
        updateInputValue('datapoints-outerRadius', v);
    }

    checkDataPointHover(pos, bounds) {
        const distance = calculateDistance(pos, this.getCenter(bounds));
        const inner = distance <= this.style.innerRadius;
        const outer = !inner && distance <= this.style.outerRadius;
        return {inner, outer};
    }

    highlightDataPoints({inner, outer}, bounds) {
        if (inner)
            this.highlightInnerRadius(bounds);
        if (outer)
            this.highlightOuterRadius(bounds);
    }

    highlightInnerRadius(bounds) {
        const ctx = this.sgfCtx;
        this.translateToCenter(ctx, bounds, () => {
            ctx.beginPath();
            ctx.arc(0, 0, this.style.innerRadius, 0, Math.PI * 2);
            ctx.closePath();
            ctx.fill();
        });
    }

    highlightOuterRadius(bounds) {
        const ctx = this.sgfCtx;
        this.translateToCenter(ctx, bounds, () => {
            ctx.beginPath();
            ctx.arc(0, 0, this.style.outerRadius, 0, Math.PI * 2);
            ctx.arc(0, 0, this.style.innerRadius, Math.PI * 2, 0, true);
            ctx.closePath();
            ctx.fill();
        });
    }

    DataPointsInspectorGroup = () => {
        const id = 'datapoints';
        return (
            <InspectorGroup
                id={`${id}`}
                title={'Data Points'}>
                <ColorInputGroup
                    id={`${id}-fillColors`}
                    label={'Color'}
                    defaultValue={this.style.fillColors[0]}
                    onChange={v => {
                        this.style.fillColors[0] = v;
                        this.onChange();
                    }}/>
                <NumberInputGroup
                    id={`${id}-outerRadius`}
                    label={'Outer Radius'}
                    defaultValue={this.style.outerRadius}
                    onBlur={v => {
                        this.style.outerRadius = v;
                        this.onChange();
                    }}/>
                <NumberInputGroup
                    id={`${id}-innerRadius`}
                    label={'Inner Radius'}
                    defaultValue={this.style.innerRadius}
                    onBlur={v => {
                        this.style.innerRadius = v;
                        this.onChange();
                    }}/>

                <h5 className={'Inspector-SubHeadline'}>Labels</h5>
                <NumberInputGroup
                    id={`${id}-labelFontSize`}
                    label={'Font Size'}
                    defaultValue={this.style.labelFontSize}
                    onBlur={v => {
                        this.style.labelFontSize = v;
                        this.onChange();
                    }}/>
                <ColorInputGroup
                    id={`${id}-labelFillColor`}
                    label={'Fill'}
                    defaultValue={this.style.labelFillColor}
                    onChange={v => {
                        this.style.labelFillColor = v;
                        this.onChange();
                    }}/>
                <ColorInputGroup
                    id={`${id}-labelStrokeColor`}
                    label={'Stroke'}
                    defaultValue={this.style.labelStrokeColor}
                    onChange={v => {
                        this.style.labelStrokeColor = v;
                        this.onChange();
                    }}/>
                <NumberInputGroup
                    id={`${id}-labelStrokeWidth`}
                    label={'Stroke Width'}
                    defaultValue={this.style.labelStrokeWidth}
                    onBlur={v => {
                        this.style.labelStrokeWidth = v;
                        this.onChange();
                    }}/>
            </InspectorGroup>
        )
    };
}

export default PieChart;