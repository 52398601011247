import {DATA_SCALE, DATA_TYPE} from "../constants/data";
// customize date parser (remove day-month, month-day, and relative time)
const parser = require('any-date-parser');
const dayMonth = require('any-date-parser/src/formats/dayMonth/dayMonth.js');
const monthDay = require('any-date-parser/src/formats/monthDay/monthDay.js');
const ago = require('any-date-parser/src/formats/ago/ago.js');
const atSeconds = require('any-date-parser/src/formats/atSeconds/atSeconds.js');
parser.removeFormat(dayMonth);
parser.removeFormat(monthDay);
parser.removeFormat(ago);
parser.removeFormat(atSeconds);
Date.fromString = parser.exportAsFunction();
Date.fromAny = parser.exportAsFunctionAny();

const getValueType = (value, decimalSep, thousandSep) => {
    if ((value ?? '').length === 0) return null;
    if (!Date.fromString(value).invalid) return DATA_TYPE.DATE;
    if (typeof value === 'number') return DATA_TYPE.INTEGER;

    // values with a leading number are interpreted as a number, e.g. "10€"
    // const normedValue = value.replace(thousandSep, '').replace(decimalSep, '.');
    // if (!isNaN(parseFloat(normedValue))) {
    //     const count = normedValue.match(/[.]/g)?.length ?? 0;
    //     if (count === 1) return DATA_TYPE.FLOAT;
    //     if (count === 0) return DATA_TYPE.INTEGER;
    // }
    return DATA_TYPE.STRING;
};

export const getVariableType = (values, decimalSep, thousandSep) => {
    const valueSet = new Set(values);
    if (valueSet.size < 1) return null;
    // determine individual data types
    const types = new Set();
    valueSet.forEach((value, key, set) => {
        types.add(getValueType(value, decimalSep, thousandSep));
    });
    types.delete(null);
    // determine overall data type
    if (types.size === 1) {
        if (valueSet.size === 2 && valueSet.has("0") && valueSet.has("1")) return DATA_TYPE.STRING;
        return types.entries().next().value[0];
    }
    if (types.size === 2) {
        if (types.has(DATA_TYPE.INTEGER) && types.has(DATA_TYPE.FLOAT)) return DATA_TYPE.FLOAT;
        // because date is often categorized wrong
        if (types.has(DATA_TYPE.DATE) && types.has(DATA_TYPE.INTEGER)) return DATA_TYPE.INTEGER;
        if (types.has(DATA_TYPE.DATE) && types.has(DATA_TYPE.FLOAT)) return DATA_TYPE.FLOAT;
    }
    if (types.size === 3) {
        if (types.has(DATA_TYPE.DATE) && types.has(DATA_TYPE.DATE) && types.has(DATA_TYPE.FLOAT)) return DATA_TYPE.FLOAT;
    }
    console.warn('inconsistent data types:', Array.from(types));
    return DATA_TYPE.STRING;
};

export const parseValue = (value, type, decimalSep, thousandSep) => {
    if (!value) return value;
    if (type === DATA_TYPE.INTEGER) return parseInt(value.replace(thousandSep, '').replace(decimalSep, '.'));
    if (type === DATA_TYPE.FLOAT) return parseFloat(value.replace(thousandSep, '').replace(decimalSep, '.'));
    if (type === DATA_TYPE.DATE) return Date.fromString(value);
    return value;
}

export const parseValues = (values, type, decimalSep, thousandSep) => {
    return values.map((value) => parseValue(value, type, decimalSep, thousandSep));
};

const isOrdinal = (values) => {
    values.sort((a, b) => a - b);
    if (values[0] !== 1) return false;
    let place = 0, gap = 1;
    for (let i = 0; i < values.length; i++) {
        if (values[i] !== place) {
            place += gap;
            gap = 1;
            if (values[i] !== place) return false;
        } else {
            gap++;
        }
    }
    return true;
};

export const getVariableScale = (values, type) => {
    if (type === DATA_TYPE.DATE) return DATA_SCALE.DATE;
    if (type === DATA_TYPE.FLOAT) return DATA_SCALE.QUANTITATIVE;
    if (type === DATA_TYPE.INTEGER) {
        if (isOrdinal(values)) return DATA_SCALE.ORDINAL;
        return DATA_SCALE.QUANTITATIVE;
    }
    return DATA_SCALE.NOMINAL;
};

export const getIsUnique = (values) => {
    const uniqueCount = new Set(values).size;
    return {
        isUnique: uniqueCount === values.length,
        count: uniqueCount
    };
};

export const getGaps = (values) => {
    return values.sort((a, b) => a - b).slice(1).map((val, valIdx) => (
        // Number(toFixed(10)) fixes floating point errors, but could cause false gaps for more than 10 fraction digits
        Number((val - values[valIdx]).toFixed(10))
    ));
};

export const getSmallestGap = (values) => {
    const gaps = getGaps(values);
    return Math.min(...gaps);
};

export const getEqualGapRatio = (values) => {
    if (values.length < 2) return null;
    values.sort((a, b) => a - b);
    const gaps = getGaps(values);
    const minGap = Math.min(...gaps);
    if (minGap === 0) return null;

    let equalGapsCount = 0;
    gaps.forEach((gap) => {
        if (gap === minGap) equalGapsCount++;
    });
    return equalGapsCount / gaps.length;
};

const getTimeOfDayInMilliseconds = (value) => {
    return ((value.getHours() * 60 + value.getMinutes()) * 60 + value.getSeconds()) * 60 + value.getMilliseconds();
};

export const getEqualGapRatioDate = (values) => {
    const uniqueTimesOfDay = new Set(values.map((value) => getTimeOfDayInMilliseconds(value)));
    const uniqueDays = new Set(values.map((value) => value.getDate()));
    // if anything beyond days changes, use milliseconds to calculate equalGapRatio
    if (uniqueDays.size > 1 || uniqueTimesOfDay.size > 1) return getEqualGapRatio(values);
    // else use months & years to calculate equalGaoRatio
    const equalizedDates = values.map((value) => value.getFullYear() * 12 + value.getMonth());
    return getEqualGapRatio(equalizedDates);
};

// arbitrary thresholds
export const getIsCyclic = (values) => {
    const months = new Set(values.map((value) => value.getMonth()));
    if (months.size > 10) return true;
    const timesOfDay = new Set(values.map((value) => getTimeOfDayInMilliseconds(value)));
    if (timesOfDay.size > 22) return true;
    return false;
};

export const getHasSignChange = (values) => {
    if (Math.min(...values) >= 0) return false;
    if (Math.max(...values) <= 0) return false;
    return true;
};

export const getRangeRatio = (values) => {
    const filteredValues = values.filter(value => !isNaN(value));
    const min = Math.min(...filteredValues);
    const max = Math.max(...filteredValues);
    if (min <= 0 && max >= 0) return 0;
    if (max < 0) return max / min;
    return min / max;
};

export const findAllIndexes = (arr, value) => {
    let indexes = [];
    let i = -1;
    while ((i = arr.indexOf(value, i+1)) !== -1) indexes.push(i);
    return indexes;
};

export const getSymmetricalSubCategories = (values, variables) => {
    const valueSet = Array.from(new Set(values)); // unique values
    return variables.filter(variable => {
        const categoryValues = valueSet.map((value) => {
            // find all occurrences of the value
            const indexes = findAllIndexes(values, value);
            // sort arrays to ensure the same array of values has the same order
            return indexes.map((idx) => variable.values[idx]).sort((a, b) => a - b);
        });
        // each array categoryValues now contains all values that have the same parent values
        // check whether each array contains exactly the same parent values
        return (
            categoryValues.every((arr) => arr.length === categoryValues[0].length)
            && categoryValues.every((arr) => arr.every((val, idx) => val === categoryValues[0][idx]))
        );
    });
};

export const getUniqueSubCategories = (values, variables) => {
    const valueSet = Array.from(new Set(values));
    return variables.filter(variable => {
        const categoryValues = valueSet.map((value) => {
            // find all occurrences of the value
            const indexes = findAllIndexes(values, value);
            // sort arrays to ensure the same array of values has the same order
            return indexes.map((idx) => variable.values[idx]).sort((a, b) => a - b);
        })
        for (let i = 0; i < categoryValues.length; i++) {
            for (let j = 0; j < categoryValues.length; j++) {
                if (i === j) break;
                // check for duplicate subcategories
                if (categoryValues[i].some(val1 => categoryValues[j].some(val2 => val1 === val2))) return false;
            }
        }
        return true;
    });
};