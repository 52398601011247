import React from "react";
import {useDataHandler} from "../context/DataContext";

const Table = () => {
    const {variables, records} = useDataHandler();

    return <table className={'Table'}>
        <thead className={'Table-Head'}>
        <tr className={'Table-Row'}>
            {variables?.map((variable, i) => (
                <th
                    className={'Table-CellHeader'}
                    key={i}>
                    {variable.name}
                </th>
            ))}
        </tr>
        </thead>
        <tbody className={'Table-Body'}>
        {records?.map((row, rowIdx) => (
            <tr className={'Table-Row'} key={rowIdx}>
                {variables.map((variable, i) => (
                    <td className={'Table-Cell'} key={i}>
                        {row[variable.name]}
                    </td>
                ))}
            </tr>
        ))}
        </tbody>
    </table>
};

export default Table;