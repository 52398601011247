import React from "react";
import {useDrag} from "react-dnd";

const DraggableVariable = ({label, variable, onDrop}) => {
    const [{opacity}, dragRef] = useDrag(() => ({
        type: 'VARIABLE',
        item: variable,
        options: {dropEffect: 'move'},
        collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0 : 1
        }),
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            onDrop(item, dropResult?.name);
        }
    }), []);
    return (
        <div
            ref={dragRef}
            style={{opacity}}
            id={`variable-${label}`}
            className={'DragAndDrop-Item'}>
            {label}
        </div>
    );
};

export default DraggableVariable;