import '../styles/editor.css';
import '../helpers/utility';
import {Outlet} from "react-router-dom";
import React from "react";
import useColorScheme from "../helpers/useColorScheme";
import Header from "./Header";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";

const Editor = () => {
    const {colorScheme} = useColorScheme();

    return (
        <div className={'Editor'}>
            <DndProvider backend={HTML5Backend}>
                <Header/>
                <div className={'Editor-Body'}>
                    <Outlet context={{}}/>
                </div>
            </DndProvider>
        </div>
    );
};

export default Editor;