import {
    getEqualGapRatio, getEqualGapRatioDate,
    getHasSignChange, getIsCyclic,
    getIsUnique,
    getRangeRatio, getSymmetricalSubCategories, getUniqueSubCategories,
    getVariableScale,
    getVariableType
} from "./dataCategorization";
import {DATA_SCALE} from "../constants/data";

export function categorizeVariables(variables, records) {
    return variables
        .map((name) => {
            const values = records.map(r => r[name]);
            return categorizeVariable(name, values);
        })
        .map((variable, i, arr) => {
            const v = {...variable};
            if (v.scale !== DATA_SCALE.NOMINAL) return v;
            // exclude current variable, because it must not be a subcategory of itself
            const otherVariables = arr.filter(v2 => v2.name !== v.name);
            v.symmetricalSubCategories = getSymmetricalSubCategories(v.values, otherVariables).map(v2 => v2.name);
            v.uniqueSubCategories = getUniqueSubCategories(v.values, otherVariables).map(v2 => v2.name);
            return v;
        });
}

function categorizeVariable(name, values) {
    const v = {name, values}
    v.selected = false;
    v.type = getVariableType(values); // todo: decimal + thousand sep
    v.scale = getVariableScale(values, v.type);
    if (v.scale === DATA_SCALE.NOMINAL) {
        const uniqueness = getIsUnique(values);
        v.isUnique = uniqueness.isUnique;
        v.uniqueValues = Array.from(new Set(values));
        v.uniqueValueCount = uniqueness.count;
    } else if (v.scale === DATA_SCALE.QUANTITATIVE) {
        const filteredValues = values.filter((value) => typeof value === 'number');
        v.minValue = Math.min(...filteredValues);
        v.maxValue = Math.max(...filteredValues);
        v.valueSum = filteredValues.reduce((prev, cur) => prev + cur, 0);
        v.equalGapRatio = getEqualGapRatio(values);
        v.hasNoSignChange = !getHasSignChange(values);
        v.rangeRatio = getRangeRatio(values);
    } else if (v.scale === DATA_SCALE.DATE) {
        v.isCyclic = getIsCyclic(values);
        v.equalGapRatio = getEqualGapRatioDate(values);
    }
    return v;
}